import React from "react"
import { useTranslate } from "react-admin"
import { Card, CardActions, CardHeader } from "@mui/material"

interface DashboardCardProps {
	title: string
	avatar?: any
	children: React.ReactNode
	actions?: React.ReactNode
}

const DashboardCard = ({ title, avatar, children, actions }: DashboardCardProps) => {
	const translate = useTranslate()

	return (
		<Card sx={{ marginBottom: 3 }}>
			<CardHeader
				avatar={avatar}
				titleTypographyProps={{ fontSize: "1.75rem" }}
				title={translate(title)}
				sx={{ borderBottom: "1px solid", borderColor: "divider" }}
			/>
			{children}
			{actions && (
				<CardActions>
					{actions}
				</CardActions>
			)}
		</Card>
	)
}

export default DashboardCard
