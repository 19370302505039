import React from "react"
import { Chip } from "@mui/material"
import { isArray, get } from "lodash"

const renderNumberChipField = (record: any, source?: string) => {
	if (!record || !source) {
		return <>—</>
	}

	const fieldValue = get(record, source)

	if (!fieldValue) {
		// eslint-disable-next-line no-console
		console.warn(`record.${source} is undefined`)

		return <>—</>
	}

	if (isArray(fieldValue) && fieldValue.length > 0) {
		return <Chip label={fieldValue.length} size="small" />
	}

	if (fieldValue > 0 || fieldValue !== "" || fieldValue !== "0") {
		return <Chip label={fieldValue} size="small" />
	}

	return <>—</>
}

export default renderNumberChipField
