import React from "react"
import { useRecordContext, useTranslate } from "react-admin"
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import SendIcon from "@mui/icons-material/Send"
import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from "@mui/icons-material/Clear"
import GroupIcon from "@mui/icons-material/Group"
import QuestionMarkIcon from "@mui/icons-material/QuestionMark"

import useStatistics from "utils/statistics"
import { SectionTitle } from "utils/SectionTitle"

const StatisticListItem = (props: any) => {
	const {
		icon,
		name,
		count,
		color
	} = props
	const translate = useTranslate()

	return (
		<ListItem>
			<ListItemAvatar>
				{/* @ts-ignore */}
				<Avatar sx={(theme) => ({ backgroundColor: theme.palette[color]?.main })}>
					{icon}
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={count}
				primaryTypographyProps={{ fontWeight: "bold", fontSize: "1.5rem", lineHeight: 1 }}
				// @ts-ignore
				sx={(theme) => ({ color: theme.palette[color]?.main })}
				secondary={translate(`resources.events.statistics.${name}`, count)}
			/>
		</ListItem>
	)
}

const EventStatistics = () => {
	const record = useRecordContext()
	const statistics = useStatistics({
		path: "events",
		id: record.id as number
	})

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6}>
				<SectionTitle label="resources.events.statistics.general_label"/>
				<List disablePadding>
					<StatisticListItem
						icon={<SendIcon/>}
						name="sent"
						count={statistics.getCountOf("sent")}
						color="primary"
					/>
					<StatisticListItem
						icon={<GroupIcon/>}
						name="total_guest"
						count={statistics.getCountOf("totalGuest")}
						color="primary"
					/>
				</List>
			</Grid>
			<Grid item xs={12} sm={6}>
				<SectionTitle label="resources.events.statistics.responses_label"/>
				<List disablePadding>
					<StatisticListItem
						icon={<CheckIcon/>}
						name="accepted"
						count={statistics.getCountOf("accepted")}
						color="success"
					/>
					<StatisticListItem
						icon={<ClearIcon/>}
						name="refused"
						count={statistics.getCountOf("refused")}
						color="error"
					/>
					<StatisticListItem
						icon={<QuestionMarkIcon/>}
						name="without_response"
						count={statistics.getCountOf("withoutResponse")}
						color="warning"
					/>
				</List>
			</Grid>
		</Grid>
	)
}

export default EventStatistics
