import React from "react"
import { useTranslate } from "react-admin"
import {
	Box,
	Typography
} from "@mui/material"

const Empty = () => {
	const translate = useTranslate()

	return (
		<Box textAlign="center" p={2} sx={{ borderBottom: "1px solid", borderColor: "divider" }}>
			<Typography>
				{translate("dashboard.partner_events.no_event")}
			</Typography>
		</Box>
	)
}

export default Empty
