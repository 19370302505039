/* eslint-disable */
const dashboardMessages = {
	dashboard: {
		events: {
			name: "Ville de Trélazé - Manifestations à venir",
			add: "Ajouter une manifestation",
			list: "Toutes les manifestations",
			no_event: "Aucune manifestation à venir"
		},
		partner_events: {
			name: "Club Partenaire - Manifestations à venir",
			add: "Ajouter une manifestation",
			list: "Toutes les manifestations",
			no_event: "Aucune manifestation à venir"
		},
		directory: {
			name: "Annuaire"
		},
		statistics: {
			contacts: {
				count_label: "contact||||contacts",
				add: "Ajouter un contact",
				list: "Tous les contacts"
			},
			organizations: {
				count_label: "organisme||||organismes",
				add: "Ajouter un organisme",
				list: "Tous les organismes"
			},
			groups: {
				count_label: "groupe||||groupes",
				add: "Ajouter un groupe",
				list: "Tous les groupes"
			}
		}
	}
}

export default dashboardMessages
