import React from "react"
import {
	IconButton,
	ListItem as MuiListItem,
	ListItemSecondaryAction,
	ListItemText
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useTranslate } from "react-admin"
import AddIcon from "@mui/icons-material/Add"
import ListIcon from "@mui/icons-material/List"

const ListItem = ({ item }: { item: any }) => {
	const translate = useTranslate()
	const navigate = useNavigate()

	return (
		<MuiListItem
			sx={{ borderBottom: "1px solid", borderColor: "divider" }}
		>
			<ListItemText
				primary={item.count}
				primaryTypographyProps={{ color: "primary", fontWeight: "bold", fontSize: "1.5rem" }}
				secondary={translate(`dashboard.statistics.${item.name}.count_label`, item.count)}
			/>
			<ListItemSecondaryAction>
				<IconButton
					onClick={() => navigate(item.name)}
					title={translate(`dashboard.statistics.${item.name}.list`)}
				>
					<ListIcon/>
				</IconButton>
				<IconButton
					onClick={() => navigate(`${item.name}/create`)}
					title={translate(`dashboard.statistics.${item.name}.add`)}
				>
					<AddIcon/>
				</IconButton>
			</ListItemSecondaryAction>
		</MuiListItem>
	)
}

export default ListItem
