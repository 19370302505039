import React from "react"
import {
	CreateButton,
	Datagrid,
	DateField,
	EditButton,
	List,
	TextField,
	TopToolbar
} from "react-admin"

import sx from "utils/sx"

const Actions = () => <TopToolbar>
	<CreateButton label="misc.create" />
</TopToolbar>

const EmailAddressesList = () => {
	return <List
		actions={<Actions />}
		title="resources.email_addresses.title"
	>
		<Datagrid
			rowClick="edit"
			bulkActionButtons={false}
			sx={sx.datagridColumns}
		>
			<TextField
				headerClassName="column-fullName"
				cellClassName="column-fullName"
				source="name"
				label="resources.email_addresses.fields.name"
			/>
			<TextField
				source="email"
				label="resources.email_addresses.fields.email"
			/>
			<DateField
				source="createdAt"
				label="resources.email_addresses.fields.created_at"
			/>
			<DateField
				source="updatedAt"
				label="resources.email_addresses.fields.updated_at"
			/>
			<EditButton
				label=""
				size="large"
				sx={sx.datagridButton}
			/>
		</Datagrid>
	</List>
}

export default EmailAddressesList
