import React, { useRef } from "react"
import { Grid } from "@mui/material"

interface FormGrid {
	columnWidths?: number[]
}

const FormGrid: React.FC<FormGrid> = ({ columnWidths = [], children }) => {

	const finalWidths = useRef<number[]>((() => {
		const columnsCount = React.Children.count(children)
		const defaultWidth = Math.floor(12 / columnsCount)

		if (columnWidths.length === 0) {
			return Array.from({ length: columnsCount }).map(() => defaultWidth)
		}

		return columnWidths
	})())

	return (
		<Grid container spacing={2}>
			{React.Children.map(children, (child, index) => <Grid item xs={12} sm={finalWidths.current[index]}>
				{child}
			</Grid>)}
		</Grid>
	)
}

export default FormGrid
