/* eslint-disable max-lines-per-function */
import React, { useState } from "react"
import { useTranslate } from "react-admin"
import {
	Box,
	Button,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { grey } from "@mui/material/colors"
import sx from "./sx"

interface MailjetVar {
	name: string
	description: string
}

interface MailjetVarsProps {
	type: "event" | "partner-event"
}

const mailjetEventVars: MailjetVar[] = [
	{
		name: "eventName",
		description: "Nom de l'événement"
	},
	{
		name: "eventDescription",
		description: "Description de l'événement"
	},
	{
		name: "eventStartDate",
		description: "Date de début de l'événement"
	},
	{
		name: "eventStartTime",
		description: "Heure de début de l'événement"
	},
	{
		name: "eventEndDate",
		description: "Date de fin de l'événement"
	},
	{
		name: "eventEndTime",
		description: "Heure de fin de l'événement"
	},
	{
		name: "eventPlace",
		description: "Lieu de l'événement"
	},
	{
		name: "contactCivility",
		description: "Civilité du destinataire"
	},
	{
		name: "contactFirstName",
		description: "Prénom du destinataire"
	},
	{
		name: "contactLastName",
		description: "Nom du destinataire"
	},
	{
		name: "responseUrl",
		description: "Lien vers la page de réponse publique"
	}
]

const mailjetPartnerEventVars = [
	{
		name: "partnerEventName",
		description: "Nom de l'événement"
	},
	{
		name: "partnerEventDescription",
		description: "Description de l'événement"
	},
	{
		name: "partnerEventStartDate",
		description: "Date de début de l'événement"
	},
	{
		name: "partnerEventStartTime",
		description: "Heure de début de l'événement"
	},
	{
		name: "partnerEventEndDate",
		description: "Date de fin de l'événement"
	},
	{
		name: "partnerEventEndTime",
		description: "Heure de fin de l'événement"
	},
	{
		name: "partnerEventPlace",
		description: "Lieu de l'événement"
	},
	{
		name: "contactCivility",
		description: "Civilité du destinataire"
	},
	{
		name: "contactFirstName",
		description: "Prénom du destinataire"
	},
	{
		name: "contactLastName",
		description: "Nom du destinataire"
	},
	{
		name: "qrCode",
		description: "Url de l'image du QR Code"
	}
]

const MailjetVars = ({ type }: MailjetVarsProps) => {
	const [hasDrawer, setHasDrawer] = useState(false)
	const translate = useTranslate()

	const items = type === "event"
		? mailjetEventVars
		: mailjetPartnerEventVars

	return (
		<>
			<Button
				size="small"
				variant="outlined"
				onClick={() => setHasDrawer(true)}
			>
				{translate("resources.events.email.fields.mailjet_vars")}
			</Button>
			<Drawer
				anchor="right"
				open={hasDrawer}
				onClose={() => setHasDrawer(false)}
			>
				<List disablePadding>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => setHasDrawer(false)}
						>
							<ListItemIcon>
								<CloseIcon />
							</ListItemIcon>
							<ListItemText
								primary={translate("resources.events.email.fields.mailjet_vars")}
								primaryTypographyProps={{ fontWeight: "bold", color: "primary" }}
							/>
						</ListItemButton>
					</ListItem>
				</List>
				<Divider />
				<Box sx={{ padding: 2, paddingBottom: 0 }}>
					<Paper variant="outlined">
						<List sx={sx.prose} disablePadding>
							<ListItem>
								<ListItemText
									primary={
										<code style={{ fontSize: ".85rem" }}>
											{"{{var:variableName}}"}
										</code>
									}
									secondary={
										<>
											{"Pour utiliser une variable, copiez ce code en remplaçant le terme "}
											<code style={{ fontSize: ".85rem", color: grey["900"] }}>
												variableName
											</code>
											{" par l'un des termes ci-dessous, puis collez le dans votre"}
											{" template Mailjet."}
										</>
									}
								/>
							</ListItem>
						</List>
					</Paper>
				</Box>
				<List sx={sx.prose}>
					{items.map(({ name, description }, index) => (
						<ListItem key={index}>
							<ListItemText
								primary={
									<code
										style={{ fontSize: ".85rem" }}
									>
										{name}
									</code>
								}
								secondary={description}
							/>
						</ListItem>
					))}
				</List>
			</Drawer>
		</>
	)
}

export default MailjetVars
