import { useEffect, useState } from "react"
import { get } from "lodash"
import Cookies from "js-cookie"

import { getUrl } from "./api"

interface IStatistics {
	[key: string]: number
}

type GetCountOf = (name: string) => number | undefined

interface UseStatisticsDirectory {
	path: "directory"
	id?: never
}

interface UseStatisticsEvent {
	path: "events"
	id: number
}

interface UseStatisticsPartnerEvent {
	path: "partner-events"
	id: number
}

type UseStatisticsProps = UseStatisticsDirectory | UseStatisticsEvent | UseStatisticsPartnerEvent

export interface UseStatistics {
	raw: IStatistics | undefined
	getCountOf: GetCountOf
}

const useStatistics: (props: UseStatisticsProps) => UseStatistics = (props: UseStatisticsProps) => {
	const {
		path,
		id
	} = props

	const [statistics, setStatistics] = useState<IStatistics>()

	useEffect(
		() => {
			const getStatistics = async () => {
				const url = id
					? `/${path}/${id}/statistics`
					: "/statistics"

				const response = await fetch(getUrl(url), {
					headers: new Headers({
						"Content-Type": "application/json",
						Authorization: `Bearer ${Cookies.get("token")}`
					}),
					method: "GET"
				})

				const data = await response.json()

				setStatistics(path === "directory"
					? data.directory
					: data
				)
			}

			getStatistics()
		},
		[]
	)

	const getCountOf: GetCountOf = (name) => get(statistics, `${name}Count`)

	return {
		raw: statistics,
		getCountOf
	}
}

export default useStatistics
