/* eslint-disable complexity, no-warning-comments, max-lines-per-function, @typescript-eslint/no-explicit-any */
import dayjs from "dayjs"
import { isEmpty } from "lodash"

const validateEmail = (values: any) => {
	const errors: any = {}

	if (values.plannedAt) {
		if (!values.name) {
			errors.name = "errors.required_value"
		}

		if (!values.emailAddressId) {
			errors.emailAddressId = "errors.required_value"
		}

		if (!values.template) {
			errors.template = "errors.required_value"
		}
	}

	return errors
}

export const validatePartnerEvent = (values: any, isEdit = false) => {
	let errors: any = {}

	if (!values.name) {
		errors.name = "errors.required_value"
	}

	if (!values.description) {
		errors.description = "errors.required_value"
	}

	if (!values.startTime) {
		errors.startTime = "errors.required_value"
	}

	if (!values.endTime) {
		errors.endTime = "errors.required_value"
	}

	if (!values.place) {
		errors.place = "errors.required_value"
	}

	if (!isEdit && values.startTime && values.endTime) {
		if (dayjs(values.startTime).diff() <= 0) {
			errors.startTime = "resources.partner_events.errors.start_time_before"
		}
	}

	if (values.startTime && values.endTime) {
		if (dayjs(values.endTime).diff(values.startTime) <= 0) {
			errors.endTime = "resources.partner_events.errors.end_time_before"
		}
	}

	if (values.email) {
		const emailErrors = validateEmail(values.email)

		if (!isEmpty(emailErrors)) {
			errors = {
				...errors,
				email: emailErrors
			}
		}
	}

	return errors
}
