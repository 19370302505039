import React from "react"
import { useGetList } from "react-admin"
import { List } from "@mui/material"

import ListItem from "./ListItem"
import Empty from "./Empty"

const EventsList = () => {
	const { data: events } = useGetList("events", {
		filter: { future: true },
		sort: { field: "startTime", order: "ASC" },
		pagination: { page: 1, perPage: 10 }
	})

	if (!events) {
		return null
	}

	if (events.length === 0) {
		return <Empty/>
	}

	return (
		<List disablePadding>
			{events.map(event => (
				<ListItem key={event.id} event={event} />
			))}
		</List>
	)
}

export default EventsList
