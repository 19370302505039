/* eslint-disable */
const eventsMessages = {
	resources: {
		events: {
			create: "Ajouter une manifestation",
			edit: "Modifier une manifestation",
			name: "Ville de Trélazé",
			title: "Liste des manifestations",
			export_addresses: "Exporter les étiquettes",
			export_contacts: "Exporter les contacts",
			details: {
				titles: {
					info: "Informations",
					event: "Évènement"
				},
				name: "Détails",
				fields: {
					name: "Titre",
					description: "Description",
					start_time: "Début de l'évènement",
					end_time: "Fin de l'évènement",
					place: "Lieu de l'évènement"
				}
			},
			recipients: {
				name: "Destinataires",
				fields: {
					contactName: "Nom",
					contacts: "Contacts",
					groups: "Groupes",
					organization: "Organisme",
					organizations: "Organismes",
					response: "Réponse",
					accepted: "Accepté",
					refused: "Refusé",
					guestCount: "Nb. présents",
					text: "Sélectionner par nom",
					add_selected_recipients:
						"Ajouter les contacts sélectionnés (%{count})",
					empty_selector:
						"Sélectionner un groupe, un organisme, ou un nom pour afficher des contacts à inviter.",
					empty_results:
						"Aucun contact correspondant aux filtres appliqués.",
					empty_selected:
						"Sélectionner au moins un contact via le sélecteur adjacent.",
					active_title: "Contacts invités à l'évènement",
					select_contacts_title: "Sélectionner des contacts",
					select_all_contacts: "Sélectionner tous les contacts ci-dessous",
					select_contact: "Sélectionner ce contact",
					save_recipient: "Enregitrer les modifications",
					undo_recipient: "Annuler les modifications",
					edit_recipient: "Modifier les réponses",
					add_recipient: "Ajouter ce contact",
					delete_recipient: "Supprimer ce contact",
					search: "Filtrer les contacts"
				}
			},
			email: {
				name: "E-mail",
				fields: {
					name: "Objet de l'email",
					planned_at: "Date d'envoi",
					template: "Template de l'email",
					email_address_id: "Expéditeur",
					template_header: "Aperçu du template",
					template_select: "Sélectionner un template pour visualiser son aperçu",
					mailjet_vars: "Variables",
					mailjet_edit: "Modifier"
				},
				errors: {
					loading: "Chargement de données mailjet en cours"
				}
			},
			follow_up: {
				name: "Relance",
				fields: {
					name: "Objet de l'email",
					planned_at: "Date d'envoi",
					template: "Template de l'email",
					email_address_id: "Expéditeur",
					template_header: "Aperçu du template",
					template_select: "Sélectionner un template pour visualiser son aperçu"
				},
				errors: {
					loading: "Chargement de données mailjet en cours"
				}
			},
			notifications: {
				create: "Manifestation %{name} créée",
				edit: "Manifestation %{name} modifiée"
			},
			statistics: {
				name: "Statistiques",
				general_label: "Général",
				sent: "invité||||invités",
				accepted: "présent||||présents",
				responses_label: "Réponses",
				refused: "refusé||||refusés",
				total_guest: "invité||||invités",
				without_response: "sans réponse"
			},
			errors: {
				start_time_before: "La date de début doit être supérieure à la date d'aujourd'hui",
				end_time_before: "La date de fin doit être supérieure à la date de debut"
			}
		}
	}
}

export default eventsMessages
