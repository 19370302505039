import React from "react"
import { Box } from "@mui/material"
import {
	BooleanInput,
	Edit,
	FormTab,
	required,
	TabbedForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh
} from "react-admin"

import FormGrid from "utils/FormGrid"
import { SectionTitle } from "utils/SectionTitle"
import sx from "utils/sx"
import FormTabWithBadge from "utils/FormTabWithBadge"
import { isPlatform } from "utils/api"

import PatronagesForm from "./PatronagesForm"

/* eslint-disable max-lines-per-function */
const OrganizationEdit = () => {
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onSuccess = (data: any) => {
		notify(
			"resources.organizations.notifications.edit",
			{
				type: "info",
				messageArgs: { name: data.name }
			}
		)
		redirect("/organizations")
		refresh()
	}

	return (
		<Edit
			mutationOptions={{ onSuccess }}
			mutationMode="pessimistic"
			title="resources.organizations.edit"
		>
			<TabbedForm>
				<FormTab label="resources.organizations.titles.default">
					<Box sx={sx.prose}>
						<TextInput
							source="name"
							label="resources.organizations.fields.name"
							validate={required()}
							fullWidth
						/>
						<TextInput
							label="resources.organizations.fields.website_url"
							source="websiteUrl"
							type="url"
							fullWidth
						/>
						<TextInput
							label="resources.organizations.fields.description"
							source="description"
							validate={required()}
							maxRows={15}
							multiline
							fullWidth
						/>
						<TextInput
							label="resources.organizations.fields.email"
							source="email"
							type="email"
							fullWidth
						/>
						<FormGrid columnWidths={[6]}>
							<TextInput
								label="resources.organizations.fields.phone_number"
								source="phoneNumber"
								fullWidth
							/>
						</FormGrid>
						<SectionTitle label="resources.organizations.titles.address" />
						<TextInput
							label="resources.organizations.fields.address.address_1"
							source="address[address1]"
							fullWidth
						/>
						<TextInput
							label="resources.organizations.fields.address.address_2"
							source="address[address2]"
							fullWidth
						/>
						<TextInput
							label="resources.organizations.fields.address.address_3"
							source="address[address3]"
							fullWidth
						/>
						<FormGrid columnWidths={[4, 8]}>
							<TextInput
								label="resources.organizations.fields.address.zip_code"
								source="address[zipCode]"
								fullWidth
							/>
							<TextInput
								label="resources.organizations.fields.address.city"
								source="address[city]"
								fullWidth
							/>
						</FormGrid>
						<BooleanInput
							label="resources.groups.fields.is_archived"
							source="isArchived"
						/>
					</Box>
				</FormTab>
				{isPlatform("arena") && (
					<FormTabWithBadge
						label="resources.organizations.titles.patronage.default"
						badge="patronages"
					>
						<PatronagesForm isEdit />
					</FormTabWithBadge>
				)}
			</TabbedForm>
		</Edit>
	)
}

export default OrganizationEdit
