import dayjs from "dayjs"
import { Translate } from "react-admin"

interface SeasonTitleRecord {
	startTime: string
	endTime: string
}

export const getSeasonTitle = ({ startTime, endTime }: SeasonTitleRecord, translate: Translate) => {

	if (startTime && endTime) {
		const start = dayjs(startTime).format("YYYY")
		const end = dayjs(endTime).format("YYYY")

		return translate("resources.organizations.titles.patronage.season_for", {
			season: start === end ? start : `${start}/${end}`
		})
	}

	return translate("resources.organizations.titles.patronage.season_unknown")
}
