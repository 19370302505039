import React from "react"
import { Box } from "@mui/material"
import {
	Edit,
	PasswordInput,
	required,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate
} from "react-admin"

import { emailValidator } from "utils"

import { Separator } from "utils/Separator"
import sx from "utils/sx"
import IUserErrors from "./i-user-errors"
import FormGrid from "utils/FormGrid"

/* eslint-disable max-lines-per-function */
const UserEdit = () => {
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()
	const translate = useTranslate()

	const validateUserEdit = (values: any) => {
		const errors: IUserErrors = {}

		if (values.password !== values.passwordConfirmation) {
			errors.passwordConfirmation = translate("errors.password_confirmation_error")
		}

		if (!emailValidator.test(values.email)) {
			errors.email = translate("errors.invalid_email_address")
		}

		return errors
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onSuccess = (data: any) => {
		notify(
			"resources.users.notifications.edit",
			{
				type: "info",
				messageArgs: {
					firstName: data.firstName,
					lastName: data.lastName
				}
			}
		)
		redirect("/users")
		refresh()
	}

	return (
		<Edit
			title="resources.users.edit"
			mutationOptions={{ onSuccess }}
			mutationMode="pessimistic"
		>
			<SimpleForm validate={validateUserEdit}>
				<Box sx={sx.prose}>
					<TextInput
						source="firstName"
						label="resources.users.fields.first_name"
						validate={required()}
						fullWidth
					/>
					<TextInput
						source="lastName"
						label="resources.users.fields.last_name"
						validate={required()}
						fullWidth
					/>
					<TextInput
						source="email"
						label="resources.users.fields.email"
						validate={required()}
						fullWidth
						type="email"
					/>
					<Separator/>
					<FormGrid>
						<PasswordInput
							source="password"
							label="resources.users.fields.password"
							fullWidth
						/>
						<PasswordInput
							source="passwordConfirmation"
							label="resources.users.fields.password_confirmation"
							fullWidth
						/>
					</FormGrid>
				</Box>
			</SimpleForm>
		</Edit>
	)
}

export default UserEdit
