import React from "react"
import { Box } from "@mui/material"
import {
	Create,
	required,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh
} from "react-admin"

import sx from "utils/sx"

const EmailAddressCreate = () => {
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onSuccess = (data: any) => {
		notify(
			"resources.email_addresses.notifications.create",
			{
				type: "info",
				messageArgs: { name: data.name }
			}
		)
		redirect("/email-addresses")
		refresh()
	}

	return (
		<Create
			mutationOptions={{ onSuccess }}
			title="resources.email_addresses.create"
		>
			<SimpleForm>
				<Box sx={sx.prose}>
					<TextInput
						label="resources.email_addresses.fields.name"
						source="name"
						validate={required()}
						fullWidth
					/>
					<TextInput
						label="resources.email_addresses.fields.email"
						source="email"
						type="email"
						validate={required()}
						fullWidth
					/>
				</Box>
			</SimpleForm>
		</Create>
	)
}

export default EmailAddressCreate
