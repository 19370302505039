/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
// eslint-disable @typescript-eslint/no-explicit-any

import Cookies from "js-cookie"
import simpleRestProvider from "ra-data-simple-rest"
import { fetchUtils } from "react-admin"
import { getUrl } from "utils/api"

const httpClient = (url, options = {}) => {
	options.headers = new Headers({ Accept: "application/json" })

	const token = Cookies.get("token")

	if (token) {
		options.headers.set("Authorization", `Bearer ${token}`)
	}

	return fetchUtils.fetchJson(url, options)
}

const apiUrl = getUrl("")

const dataProvider = simpleRestProvider(apiUrl, httpClient)

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = ({ file }) => new Promise((resolve, reject) => {
	const reader = new FileReader()

	reader.onload = () => resolve(reader.result)
	reader.onerror = reject

	reader.readAsDataURL(file.rawFile)
})


const handlePatronageAttachment = async (attachment, type) => {

	if (!attachment.file) {
		return null
	}

	if (!(attachment.file.rawFile instanceof File)) {
		return attachment
	}

	const base64File = await convertFileToBase64(attachment)

	return {
		url: base64File,
		type,
		file: attachment.file
	}
}

const customDataProvider = {
	...dataProvider,
	update: async (resource, params) => {
		if (resource !== "organizations") {
			// Fallback to the default implementation
			return dataProvider.update(resource, params)
		}

		const updatedPatronages = []

		for (const patronage of params.data.patronages) {

			const contract = await handlePatronageAttachment(patronage.attachments.contract, 0)

			const convention = await handlePatronageAttachment(patronage.attachments.convention, 1)

			updatedPatronages.push({
				...patronage,
				attachments: {
					contract,
					convention
				}
			})
		}

		return dataProvider.update(resource, {
			id: params.data.id,
			data: {
				...params.data,
				patronages: updatedPatronages
			}
		})
	}
}

export default customDataProvider
