import { merge } from "lodash"
import frenchMessages from "ra-language-french"

import defaultMessages from "./default.fr"
import dashboardMessages from "./dashboard"
import usersMessages from "./users.fr"
import jobsMessages from "./jobs.fr"
import groupsMessages from "./groups.fr"
import emailAddressesMessages from "./email-addresses.fr"
import organizationsMessages from "./organizations.fr"
import contactsMessages from "./contacts.fr"
import eventsMessages from "./events.fr"
import partnerEventsMessages from "./partner-events.fr"
import noticesMessages from "./notices.fr"
import patronagePacksMessages from "./patronage-packs"

const messages = merge(
	frenchMessages,
	defaultMessages,
	dashboardMessages,
	usersMessages,
	jobsMessages,
	groupsMessages,
	emailAddressesMessages,
	organizationsMessages,
	contactsMessages,
	eventsMessages,
	noticesMessages,
	partnerEventsMessages,
	patronagePacksMessages
)

export default messages
