import React from "react"
import { AppBar } from "react-admin"
import {
	Badge,
	Box,
	Typography,
	useMediaQuery,
	Theme
} from "@mui/material"
import NotificationsIcon from "@mui/icons-material/Notifications"
import { Link } from "react-router-dom"

import { useNotice } from "contexts/notice.context"
import CustomUserMenu from "./UserMenu"

const CustomAppBar = (props: any) => {
	const { countOfUnread } = useNotice()

	const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"))

	return (
		<AppBar
			{...props}
			color="primary"
			elevation={1}
			userMenu={<CustomUserMenu />}
		>
			<Typography
				variant="h6"
				color="inherit"
				sx={{
					flex: 1,
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					overflow: "hidden",
					fontWeight: "700"
				}}
				id="react-admin-title"
			/>

			{isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}

			<Badge
				badgeContent={countOfUnread}
				color="success"
				component={Link}
				to="/notices"
			>
				<NotificationsIcon sx={{ color: "white" }} />
			</Badge>
		</AppBar>
	)
}

export default CustomAppBar
