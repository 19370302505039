import React, { useEffect, useState } from "react"
import {
	Edit,
	FormTab,
	SaveButton,
	ShowButton,
	TabbedForm,
	Toolbar,
	TopToolbar,
	useNotify,
	useRedirect,
	useRefresh
} from "react-admin"
import BarChartIcon from "@mui/icons-material/BarChart"

import PatronagesTab from "./PatronagesTab"
import DetailTab from "./DetailTab"
import EmailTab from "./EmailTab"
import { prepareData } from "./prepareData"
import FormTabWithBadge from "utils/FormTabWithBadge"
import { fetchTemplatesData } from "utils/mailjet"
import { ITemplateData } from "../events/interfaces/i-template-data"
import { validatePartnerEvent } from "./validate-partner-event"

const EventEditToolbar = () => <Toolbar>
	<SaveButton alwaysEnable={true} />
</Toolbar>

const Actions = () => <TopToolbar>
	<ShowButton
		label="resources.partner_events.statistics.name"
		icon={<BarChartIcon/>}
	/>
</TopToolbar>

const PartnerEventEdit = () => {
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onSuccess = (data: any) => {
		notify(
			"resources.partner_events.notifications.edit",
			{
				type: "info",
				messageArgs: { name: data.name }
			}
		)
		redirect("/partner-events")
		refresh()
	}

	const [templatesData, setTemplatesData] = useState<ITemplateData[]>()

	useEffect(
		() => {
			const init = async () => {
				setTemplatesData(await fetchTemplatesData())
			}

			init()
		},
		[]
	)

	return (
		<Edit
			mutationOptions={{ onSuccess }}
			mutationMode="pessimistic"
			transform={prepareData}
			title="resources.partner_events.edit"
			actions={<Actions/>}
		>
			<TabbedForm
				toolbar={<EventEditToolbar />}
				validate={(values: any) => validatePartnerEvent(values, true)}
			>
				<FormTab label="resources.partner_events.details.name">
					<DetailTab isEdit />
				</FormTab>

				<FormTabWithBadge label="resources.partner_events.organizations.name" badge="partnerEventPatronages">
					<PatronagesTab />
				</FormTabWithBadge>

				<FormTab label="resources.partner_events.email.name">
					<EmailTab templatesData={templatesData} type="email" />
				</FormTab>
			</TabbedForm>
		</Edit>
	)
}


export default PartnerEventEdit
