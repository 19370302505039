/* eslint-disable max-lines-per-function, @typescript-eslint/no-explicit-any */
import { Box } from "@mui/material"
import React from "react"
import {
	AutocompleteArrayInput,
	AutocompleteInput,
	RadioButtonGroupInput,
	ReferenceArrayInput,
	ReferenceInput,
	TextInput
} from "react-admin"
import FormGrid from "utils/FormGrid"

import { SectionTitle } from "utils/SectionTitle"
import sx from "utils/sx"

const ContactAddressForm = (props: any) => {

	return (
		<Box sx={sx.prose}>
			{props.source !== "individual"
				&& <ReferenceInput
					source={`${props.source}.organizationId`}
					reference="organizations"
					sort={{ field: "name", order: "ASC" }}
					perPage={200}
				>
					<AutocompleteInput
						label="resources.contacts.fields.organization"
					/>
				</ReferenceInput>
			}
			<ReferenceArrayInput
				source={`${props.source}.groupIds`}
				reference="groups"
				sort={{ field: "name", order: "ASC" }}
				perPage={200}
			>
				<AutocompleteArrayInput
					label="resources.contacts.fields.groups"
					optionText="name"
					fullWidth
				/>
			</ReferenceArrayInput>

			<ReferenceInput
				source={`${props.source}.jobId`}
				reference="jobs"
				defaultValue={null}
				allowEmpty={true}
				sort={{ field: "name", order: "ASC" }}
				perPage={200}
			>
				<AutocompleteInput
					label="resources.contacts.fields.job"
				/>
			</ReferenceInput>

			<RadioButtonGroupInput
				source={`${props.source}.sendType`}
				label="resources.contacts.fields.send_type"
				defaultValue={null}
				choices={[
					{ id: 1, name: "resources.contacts.fields.send_types.email" },
					{ id: 2, name: "resources.contacts.fields.send_types.post" }
				]}
			/>

			<TextInput
				label="resources.contacts.fields.email"
				source={`${props.source}.email`}
				defaultValue={null}
				type="email"
				fullWidth
			/>

			<FormGrid>
				<TextInput
					label="resources.contacts.fields.phone_number"
					source={`${props.source}.phoneNumber`}
					defaultValue={null}
					fullWidth
				/>
				<TextInput
					label="resources.contacts.fields.mobile_number"
					source={`${props.source}.mobileNumber`}
					defaultValue={null}
					fullWidth
				/>
			</FormGrid>

			<SectionTitle label="resources.contacts.titles.address" />

			<TextInput
				label="resources.contacts.fields.address.address_1"
				source={`${props.source}.address.address1`}
				defaultValue={null}
				fullWidth
			/>

			<TextInput
				label="resources.contacts.fields.address.address_2"
				source={`${props.source}.address.address2`}
				defaultValue={null}
				fullWidth
			/>

			<TextInput
				label="resources.contacts.fields.address.address_3"
				source={`${props.source}.address.address3`}
				defaultValue={null}
				fullWidth
			/>
			<FormGrid columnWidths={[4, 8]}>
				<TextInput
					label="resources.contacts.fields.address.zip_code"
					source={`${props.source}.address.zipCode`}
					defaultValue={null}
					fullWidth
				/>
				<TextInput
					label="resources.contacts.fields.address.city"
					source={`${props.source}.address.city`}
					defaultValue={null}
					fullWidth
				/>
			</FormGrid>
		</Box>
	)
}

export default ContactAddressForm
