/* eslint-disable max-lines-per-function, multiline-ternary, no-extra-parens, no-nested-ternary, id-length */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"

import {
	Button,
	Card,
	CardActions,
	CircularProgress
} from "@mui/material"
import {
	Form,
	required,
	TextInput,
	useTranslate,
	useLogin,
	useNotify
} from "react-admin"

import Box from "@mui/material/Box"
import { styled } from "@mui/system"

import { isPlatform, getPlatform } from "../utils/api"

interface FormValues {
	username?: string
	password?: string
}

const Login = () => {
	const [loading, setLoading] = useState(false)
	const translate = useTranslate()

	const notify = useNotify()
	const login = useLogin()
	const location = useLocation()

	const handleSubmit = (auth: FormValues) => {
		setLoading(true)
		login(
			auth,
			location.state ? (location.state as any).nextPathname : "/"
		).catch((error: Error) => {
			setLoading(false)
			notify(
				typeof error === "string"
					? error
					: typeof error === "undefined" || !error.message
						? "ra.auth.sign_in_error"
						: error.message,
				{
					type: "warning",
					messageArgs: {
						_:
							typeof error === "string"
								? error
								: error && error.message
									? error.message
									: undefined
					}
				}
			)
		})
	}

	const Title = styled("h1")(({ theme }) => ({
		fontSize: "1.5em",
		color: theme.palette.primary.main,
		margin: ".65em 0 0"
	}))

	return (
		<Form onSubmit={handleSubmit} noValidate>
			<Box
				sx={(theme) => ({
					display: "flex",
					fontFamily: theme.typography.fontFamily,
					flexDirection: "column",
					minHeight: "100vh",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: theme.palette.primary.main
				})}
			>
				<Card sx={{ minWidth: 300 }}>
					<Box
						sx={{
							margin: "1.25em",
							display: "flex",
							flexDirection: "column",
							alignItems: "center"
						}}
					>
						{isPlatform("arena")
							? <img src="/logo-arena-loire-small.jpg" width="192" />
							: <img src="/logo-trelaze.svg" width="128" />
						}
						<Title>{translate(`platform.${getPlatform()}.name`)}</Title>
					</Box>
					<Box sx={{ padding: "0 1.25em 1em 1.25em" }}>
						<Box>
							<TextInput
								autoFocus
								source="username"
								label={translate("ra.auth.username")}
								disabled={loading}
								validate={required()}
								fullWidth
							/>
						</Box>
						<Box>
							<TextInput
								source="password"
								label={translate("ra.auth.password")}
								type="password"
								disabled={loading}
								validate={required()}
								fullWidth
							/>
						</Box>
					</Box>
					<CardActions sx={{ padding: "0 1.25em 1.25em 1.25em" }}>
						<Button
							variant="contained"
							type="submit"
							color="primary"
							disabled={loading}
							fullWidth
						>
							{loading && (
								<CircularProgress size={25} thickness={2} />
							)}
							{translate("ra.auth.sign_in")}
						</Button>
					</CardActions>
				</Card>
			</Box>
		</Form>
	)
}

Login.propTypes = {
	authProvider: PropTypes.func,
	previousRoute: PropTypes.string
}

export default Login
