import React from "react"
import {
	CreateButton,
	Datagrid,
	DateField,
	EditButton,
	FunctionField,
	List,
	NumberField,
	TextField,
	TopToolbar
} from "react-admin"

import sx from "utils/sx"
import renderNumberChipField from "utils/renderNumberChipField"

const Actions = () => <TopToolbar>
	<CreateButton label="misc.create" />
</TopToolbar>

const PatronagePacksList = () => {
	return <List
		actions={<Actions />}
		title="resources.patronage_packs.title"
	>
		<Datagrid
			rowClick="show"
			bulkActionButtons={false}
			sx={sx.datagridColumns}
		>
			<TextField
				source="name"
				label="resources.patronage_packs.fields.name"
			/>
			<NumberField
				source="price"
				options={{ style: "currency", currency: "EUR" }}
				label="resources.patronage_packs.fields.price"
			/>
			<FunctionField
				headerClassName="column-number"
				cellClassName="column-number"
				source="ticketNumbers"
				label="resources.patronage_packs.fields.ticket_numbers"
				render={renderNumberChipField}
			/>
			<DateField
				source="createdAt"
				label="resources.patronage_packs.fields.created_at"
			/>
			<DateField
				source="updatedAt"
				label="resources.patronage_packs.fields.updated_at"
			/>
			<EditButton
				label=""
				size="large"
				sx={sx.datagridButton}
			/>
		</Datagrid>
	</List>
}

export default PatronagePacksList
