/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from "react"
import { find, get, isString, ListIteratee, orderBy } from "lodash"
import {
	Box,
	Button,
	SxProps,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Theme
} from "@mui/material"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { useTranslate } from "react-admin"

type SortDirection = "asc" | "desc"

interface DataGridColumn<Item> {
	name: string
	header: React.ReactNode
	renderItem?: (item: Item, index: number) => React.ReactNode
	sortable?: boolean
	customSort?: ListIteratee<Item>
	sx?: SxProps<Theme>
}

interface DataGridItem {
	[x: string]: any
}

interface DataGridProps<Item extends DataGridItem> {
	columns: DataGridColumn<Item>[]
	items: Item[]
	pageRef?: React.MutableRefObject<number>
	noPagination?: boolean
	defaultSort?: {
		column: string
		direction?: SortDirection
	}
	sx?: SxProps<Theme>
}

const DataGrid = <Item extends DataGridItem>({
	columns,
	pageRef,
	sx,
	defaultSort,
	noPagination,
	...props
}: DataGridProps<Item>) => {
	const translate = useTranslate()

	const [items, setItems] = useState<Item[]>([])
	const [page, setPage] = useState(pageRef?.current || 0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [sortColumn, setSortColumn] = useState<string | null>(defaultSort?.column || null)
	const [sortDirection, setSortDirection] = useState<SortDirection>(defaultSort?.direction || "asc")

	const getPageItems = () => {
		if (noPagination) {
			return items
		}

		const firstIndex = rowsPerPage * page

		return items.slice(firstIndex, firstIndex + rowsPerPage)
	}

	const [pageItems, setPageItems] = useState<Item[]>(getPageItems())

	useEffect(
		() => {
			if (page === 0) {
				setPageItems(getPageItems())
			} else {
				const maxPage = Math.ceil(items.length / rowsPerPage)
				const currentPage = pageRef?.current || 0

				setPage(currentPage > maxPage ? 0 : currentPage)
			}
		},
		[items]
	)

	useEffect(
		() => {
			if (sortColumn === null) {
				setItems(props.items)

				return
			}

			const column = find(columns, ["name", sortColumn])

			if (!column) {
				setItems(props.items)

				return
			}

			setItems(orderBy(props.items, column.customSort || sortColumn, sortDirection))
		},
		[props.items, sortColumn, sortDirection]
	)

	useEffect(
		() => {
			if (pageRef) {
				pageRef.current = page
			}
		},
		[page]
	)

	useEffect(
		() => {
			setPageItems(getPageItems())
		},
		[page, rowsPerPage]
	)

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	return (
		<Box sx={sx}>
			<Table>
				<TableHead>
					<TableRow>
						{ columns.map(({ header, name, sortable, sx }, index) => (
							<TableCell key={index} sx={ sx } className={`column-${name}`}>
								{!sortable && (
									isString(header)
										? translate(header)
										: header
								)}
								{sortable && (
									<Button
										variant="text"
										size="small"
										onClick={() => {
											setSortColumn(name)
											setSortDirection((dir) => (
												dir === "asc" ? "desc" : "asc"
											))
										}}
										endIcon={
											<ArrowDownwardIcon
												sx={{
													transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
													transform: sortDirection === "asc" ? "rotate(180deg)" : undefined
												}}
											/>
										}
										sx={{
											color: "currentcolor",
											textTransform: "none",
											marginY: -1
										}}
										data-field={name}
										data-order={sortDirection.toUpperCase()}
										aria-label="Trier"
									>
										{isString(header)
											? translate(header)
											: header
										}
									</Button>
								)}
							</TableCell>
						)) }
					</TableRow>
				</TableHead>
				<TableBody>
					{ pageItems.map((item, index) => (
						<TableRow key={index}>
							{ columns.map(({ name, renderItem, sx }, colIndex) => (
								<TableCell key={`${index}-${colIndex}`} sx={ sx } className={`column-${name}`}>
									{ (!!renderItem && renderItem(item, index)) || get(item, name) || "—" }
								</TableCell>
							)) }
						</TableRow>
					)) }
				</TableBody>
			</Table>
			{!noPagination && (
				<TablePagination
					component="div"
					count={items.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Box>
	)
}

export default DataGrid
