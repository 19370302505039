import React from "react"
import {
	EditButton,
	Show,
	SimpleShowLayout,
	Title,
	TopToolbar,
	useTranslate
} from "react-admin"

import PartnerEventStatistics from "./PartnerEventStatistics"

const Actions = () => <TopToolbar>
	<EditButton label="misc.edit" />
</TopToolbar>

const PartnerEventShow = () => {
	const translate = useTranslate()

	return (
		<Show
			actions={<Actions />}
		>
			<Title title={` - ${translate("resources.partner_events.statistics.name")}`} />
			<SimpleShowLayout>
				<PartnerEventStatistics/>
			</SimpleShowLayout>
		</Show>
	)
}

export default PartnerEventShow
