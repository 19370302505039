import React from "react"
import { DateTimeInput, required, TextInput } from "react-admin"
import dayjs from "dayjs"
import { Box } from "@mui/material"
import { useFormContext } from "react-hook-form"

import { SectionTitle } from "utils/SectionTitle"
import sx from "utils/sx"
import FormGrid from "utils/FormGrid"

const DetailTab = ({ isEdit = false }) => {
	const { getValues } = useFormContext()

	const minStartDate = isEdit ? undefined : dayjs().format("YYYY-MM-DDTHH:mm")
	const minEndDate = dayjs(getValues("startTime")).format("YYYY-MM-DDTHH:mm")

	return (
		<Box sx={sx.prose}>
			<SectionTitle label="resources.events.details.titles.info" />
			<TextInput
				source="name"
				validate={required()}
				label="resources.events.details.fields.name"
				fullWidth
			/>
			<TextInput
				multiline
				source="description"
				validate={required()}
				label="resources.events.details.fields.description"
				fullWidth
			/>

			<SectionTitle label="resources.events.details.titles.event" />
			<FormGrid>
				<DateTimeInput
					label="resources.events.details.fields.start_time"
					source="startTime"
					validate={required()}
					inputProps={{ min: minStartDate }}
					fullWidth
				/>
				<DateTimeInput
					label="resources.events.details.fields.end_time"
					validate={required()}
					source="endTime"
					inputProps={{ min: minEndDate }}
					fullWidth
				/>
			</FormGrid>
			<TextInput
				multiline
				label="resources.events.details.fields.place"
				source="place"
				validate={required()}
				fullWidth
			/>
		</Box>
	)
}

export default DetailTab
