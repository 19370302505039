import React from "react"
import {
	FileField,
	FileInput
} from "react-admin"

import { useFormContext } from "react-hook-form"

interface PatronageAttachmentFormProps {
	sectionTitle?: string
	source: string
	isRequired?: boolean
}

const PatronageAttachmentForm = ({
	sectionTitle = "Document n°1",
	source,
	isRequired = false
}: PatronageAttachmentFormProps) => {

	return (
		<div style={{
			flex: 1,
			flexDirection: "column"
		}}>
			<h4>{sectionTitle}</h4>
			<FileInput
				isRequired={isRequired}
				label="Fichier"
				source={`${source}.file`}
				accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			>
				<FileField
					source="src"
					title="title"
					download
				/>
			</FileInput>
		</div>
	)
}

export default PatronageAttachmentForm
