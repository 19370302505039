/* eslint-disable max-lines-per-function */
import React from "react"
import Cookies from "js-cookie"

import {
	Admin,
	fetchUtils,
	Resource,
	useTranslate
} from "react-admin"
import simpleRestProvider from "ra-data-simple-rest"
import polyglotI18nProvider from "ra-i18n-polyglot"

import authProvider from "authProvider"
import { NoticeProvider } from "./contexts/notice.context"

import dayjs from "dayjs"
import "dayjs/locale/fr"
dayjs.locale("fr")
import isBetween from "dayjs/plugin/isBetween"
dayjs.extend(isBetween)

import { theme } from "theme"

import Layout from "layout/Layout"
import NotFound from "utils/NotFound"
import LoginPage from "login/Login"
import Dashboard from "dashboard/Dashboard"
import frenchMessages from "translations/fr"
import { getPlatform, getUrl } from "utils/api"

import UserCreate from "admin/users/UserCreate"
import UserEdit from "admin/users/UserEdit"
import UsersList from "admin/users/UsersList"

import JobCreate from "admin/jobs/JobCreate"
import JobEdit from "admin/jobs/JobEdit"
import JobsList from "admin/jobs/JobsList"

import GroupCreate from "admin/groups/GroupCreate"
import GroupEdit from "admin/groups/GroupEdit"
import GroupsList from "admin/groups/GroupsList"

import EmailAddressCreate from "admin/email-addresses/EmailAddressCreate"
import EmailAddressEdit from "admin/email-addresses/EmailAddressEdit"
import EmailAddressesList from "admin/email-addresses/EmailAddressesList"

import OrganizationCreate from "admin/organizations/OrganizationCreate"
import OrganizationEdit from "admin/organizations/OrganizationEdit"
import OrganizationsList from "admin/organizations/OrganizationsList"

import ContactCreate from "admin/contacts/ContactCreate"
import ContactEdit from "admin/contacts/ContactEdit"
import ContactsList from "admin/contacts/ContactsList"

import PatronagePackCreate from "admin/patronage-packs/PatronagePackCreate"
import PatronagePackEdit from "admin/patronage-packs/PatronagePackEdit"
import PatronagePacksList from "admin/patronage-packs/PatronagePacksList"

import EventCreate from "admin/events/EventCreate"
import EventEdit from "admin/events/EventEdit"
import EventsList from "admin/events/EventsList"
import EventShow from "admin/events/EventShow"

import PartnerEventCreate from "admin/partner-events/PartnerEventCreate"
import PartnerEventEdit from "admin/partner-events/PartnerEventEdit"
import PartnerEventsList from "admin/partner-events/PartnerEventsList"
import PartnerEventShow from "admin/partner-events/PartnerEventShow"

import NoticesList from "admin/notices/NoticesList"
import customDataProvider from "customDataProvider"

const i18nProvider = polyglotI18nProvider(() => frenchMessages, "fr")

const App = () => {
	const translate = useTranslate()

	return (
		<NoticeProvider>
			<Admin
				catchAll={NotFound}
				dashboard={Dashboard}
				loginPage={LoginPage}
				dataProvider={customDataProvider}
				i18nProvider={i18nProvider}
				disableTelemetry
				layout={Layout}
				title={translate(`platform.${getPlatform()}.name`)}
				authProvider={authProvider}
				theme={theme}
			>
				<Resource
					name="groups"
					create={GroupCreate}
					edit={GroupEdit}
					list={GroupsList}
					options={{ menuParent: "directory" }}
				/>
				<Resource
					name="organizations"
					create={OrganizationCreate}
					edit={OrganizationEdit}
					list={OrganizationsList}
					options={{ menuParent: "directory" }}
				/>
				<Resource
					name="contacts"
					list={ContactsList}
					create={ContactCreate}
					edit={ContactEdit}
					options={{ menuParent: "directory" }}
				/>
				<Resource
					name="events"
					create={EventCreate}
					edit={EventEdit}
					list={EventsList}
					show={EventShow}
					options={{ menuParent: "events" }}
				/>
				<Resource
					name="partner-events"
					create={PartnerEventCreate}
					edit={PartnerEventEdit}
					list={PartnerEventsList}
					show={PartnerEventShow}
					options={{ menuParent: "events" }}
				/>
				<Resource
					name="events"
					create={EventCreate}
					edit={EventEdit}
					list={EventsList}
					show={EventShow}
					options={{ menuParent: "events" }}
				/>
				<Resource
					name="users"
					create={UserCreate}
					list={UsersList}
					edit={UserEdit}
					options={{ menuParent: "admin" }}
				/>
				<Resource
					name="jobs"
					create={JobCreate}
					edit={JobEdit}
					list={JobsList}
					options={{ menuParent: "admin" }}
				/>
				<Resource
					name="email-addresses"
					create={EmailAddressCreate}
					edit={EmailAddressEdit}
					list={EmailAddressesList}
					options={{ menuParent: "admin" }}
				/>
				<Resource
					name="patronage-packs"
					create={PatronagePackCreate}
					list={PatronagePacksList}
					edit={PatronagePackEdit}
					options={{ menuParent: "admin" }}
				/>
				<Resource
					name="notices"
					list={NoticesList}
				/>
			</Admin>
		</NoticeProvider>
	)
}

export default App
