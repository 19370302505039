import { SxProps, Theme } from "@mui/material"

const prose: SxProps<Theme> = {
	width: "100%",
	maxWidth: "60ch"
}

const paperSectionTitle: SxProps<Theme> = {
	paddingX: "1rem",
	paddingY: ".5rem",
	borderBottom: "1px solid",
	borderColor: "divider",
	marginBottom: 0
}

const datagridButton: SxProps<Theme> = {
	minWidth: "auto",
	"& .MuiButton-startIcon": {
		margin: 0
	}
}

const whiteSpaceNowrap: SxProps<Theme> = {
	whiteSpace: "nowrap"
}

const columnAuto: SxProps<Theme> = {
	width: "auto"
}

const column50: SxProps<Theme> = {
	width: "50%"
}

const column30: SxProps<Theme> = {
	width: "30%"
}

const column25: SxProps<Theme> = {
	width: "25%"
}

const column10: SxProps<Theme> = {
	width: "10%"
}

const columnIsArchived: SxProps<Theme> = {
	width: "4em",
	textAlign: "center"
}

const columnButton: SxProps<Theme> = {
	width: "2em",
	textAlign: "center"
}

const datagridColumns: SxProps<Theme> = {
	"& .column-undefined": columnButton,
	"& .column-civility": column10,
	"& .column-name": column50,
	"& .column-fullName": columnAuto,
	"& .column-firstName": column50,
	"& .column-lastName": column50,
	"& .column-groups": {
		...column25,
		textAlign: "left"
	},
	"& .column-organizations": {
		...column25,
		textAlign: "left"
	},
	"& .column-phoneNumber": {
		...column25,
		...whiteSpaceNowrap
	},
	"& .column-price": {
		...column25,
		...whiteSpaceNowrap,
		textAlign: "left"
	},
	"& .column-email": {
		...column30,
		...whiteSpaceNowrap
	},
	"& .column-createdAt": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-updatedAt": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-email-plannedAt": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-followUp-plannedAt": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-startTime": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-endTime": {
		...column10,
		...whiteSpaceNowrap
	},
	"& .column-number": {
		...column10,
		...whiteSpaceNowrap,
		textAlign: "left"
	},
	"& .column-isArchived": columnIsArchived,
	"& .column-patronages": columnIsArchived
}


export default {
	prose,
	column50,
	column30,
	column25,
	column10,
	paperSectionTitle,
	datagridButton,
	datagridColumns
}
