import { defaultTheme } from "react-admin"

export const theme = {
	...defaultTheme,
	palette: {
		type: "light",
		primary: {
			main: "#005da9",
			light: "#337dba",
			dark: "#004f90"
		},
		secondary: {
			main: "#f08013",
			contrastText: "#000000",
			dark: "#cc6d10",
			light: "#f39942"
		},
		background: {
			default: "#fafafa",
			paper: "#ffffff"
		}
	},
	typography: {
		fontSize: 16,
		fontFamily: "aaux-next, sans-serif"
	}
}
