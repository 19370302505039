import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import { Logout, UserMenu, useTranslate } from "react-admin"
import { Link } from "react-router-dom"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"

import { getUrl } from "utils/api"

const NotificationMenu = React.forwardRef((props, ref) => {
	const translate = useTranslate()
	const [userId, setUserId] = useState(0)

	const getUserId = async () => {
		const response = await fetch(getUrl("/users/me"), {
			headers: new Headers({
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`
			}),
			method: "GET"
		})

		const user = await response.json()

		setUserId(user.id)
	}

	useEffect(() => {
		getUserId()
	}, [])

	return (
		<MenuItem
			// @ts-ignore
			ref={ref}
			component={Link}
			{...props}
			to={`/users/${userId}/show`}
		>
			<ListItemIcon sx={{ minWidth: 5 }}>
				<AccountCircleIcon />
			</ListItemIcon>
			<ListItemText>
				{translate("resources.users.profile")}
			</ListItemText>
		</MenuItem>
	)
})

const CustomUserMenu = () => <UserMenu>
	<NotificationMenu />
	<Logout />
</UserMenu>

export default CustomUserMenu
