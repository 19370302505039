/* eslint-disable consistent-return, max-lines-per-function */
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import {
	Alert,
	Box,
	Button,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText
} from "@mui/material"
import {
	ArrayInput,
	BooleanInput,
	DateInput,
	NumberInput,
	SimpleFormIterator,
	useTranslate
} from "react-admin"
import { useFormContext } from "react-hook-form"
import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"

import { getPrice } from "utils/price"
import FormGrid from "utils/FormGrid"

interface PatronagePaymentScheduleFormProps {
	source?: string
	seasonStart: string
	seasonEnd: string
}

interface PatronagePaymentSchedulesFormProps {
	source: string
	seasonTitle: string
}

type PaymentSchedule = {
	date: string
	amount: number
	isPaid: boolean
}

const PatronagePaymentScheduleForm = ({ source, seasonStart, seasonEnd }: PatronagePaymentScheduleFormProps) => {
	const { getValues } = useFormContext()

	const isPaid = getValues(`${source}.isPaid`)

	return (
		<FormGrid>
			<DateInput
				label="resources.organizations.fields.patronage.payment_schedules.date"
				source={`${source}.date`}
				disabled={isPaid}
				inputProps={{
					min: seasonStart,
					max: seasonEnd
				}}
				fullWidth
			/>
			<NumberInput
				label="resources.organizations.fields.patronage.payment_schedules.amount"
				source={`${source}.amount`}
				type="number"
				step={0.1}
				disabled={isPaid}
				fullWidth
			/>
			<BooleanInput
				label="resources.organizations.fields.patronage.payment_schedules.is_paid"
				source={`${source}.isPaid`}
				sx={{ marginTop: 2 }}
			/>
		</FormGrid>
	)
}

const PatronagePaymentSchedulesForm = ({ seasonTitle, ...props }: PatronagePaymentSchedulesFormProps) => {
	const translate = useTranslate()
	const [hasDrawer, setHasDrawer] = useState(false)
	const { getValues, setValue } = useFormContext()

	const source = `${props.source}.paymentSchedules`
	const formValues = getValues(props.source) || {}
	const values: PaymentSchedule[] = formValues.paymentSchedules || []

	const donationAmount = Number(formValues.amount)
	const paymentSchedulesTotal = values.reduce((prev, next: PaymentSchedule) => (
		next ? prev + (next.amount * 100) : prev
	), 0) / 100

	useEffect(
		() => {
			if (values.length > 0) {
				return
			}

			setValue(source, Array(1).fill({
				date: formValues.startTime,
				amount: 0.0,
				isPaid: false
			}))
		},
		[]
	)

	useEffect(
		() => {
			if (values.length === 0) {
				return
			}

			const amountInCents = Math.round(donationAmount * 100)
			const freePartIndexes: number[] = []
			const fixedPartInCents = values.reduce((prev, next: PaymentSchedule, index) => {
				if (next && next.isPaid) {
					return prev + (next.amount * 100)
				}
				freePartIndexes.push(index)

				return prev
			}, 0)
			const partInCents = Math.round(
				(amountInCents - fixedPartInCents) / (freePartIndexes.length)
			)
			const restInCents = amountInCents - fixedPartInCents - (partInCents * (freePartIndexes.length - 1))

			setValue(source, values.map((value, index) => ({
				...value,
				amount: freePartIndexes.includes(index)
					? (index === freePartIndexes[freePartIndexes.length - 1] ? restInCents : partInCents) / 100
					: value.amount
			})))
		},
		[values.length, donationAmount]
	)

	return (
		<>
			<Button
				variant="outlined"
				startIcon={<AddIcon />}
				sx={{ marginBottom: 4 }}
				onClick={() => setHasDrawer(true)}
				disabled={!donationAmount || !formValues.startTime || !formValues.endTime}
			>
				{translate("resources.organizations.fields.patronage.payment_schedules.button")}
			</Button>
			<Drawer
				anchor="right"
				open={hasDrawer}
				onClose={() => setHasDrawer(false)}
			>
				<List disablePadding>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => setHasDrawer(false)}
						>
							<ListItemIcon>
								<CloseIcon />
							</ListItemIcon>
							<ListItemText
								primary={[
									seasonTitle,
									translate("resources.organizations.fields.patronage.payment_schedules.name")
								].join(" - ")}
								primaryTypographyProps={{ fontWeight: "bold", color: "primary" }}
							/>
						</ListItemButton>
					</ListItem>
				</List>
				<Divider />
				<Box sx={{ marginX: 2 }}>
					<ArrayInput source={source} label="">
						<SimpleFormIterator>
							<PatronagePaymentScheduleForm
								seasonStart={dayjs(formValues.startTime).format("YYYY-MM-DD")}
								seasonEnd={dayjs(formValues.endTime).format("YYYY-MM-DD")}
							/>
						</SimpleFormIterator>
					</ArrayInput>
					{donationAmount !== paymentSchedulesTotal && (
						<Alert severity="error">
							{translate(
								"resources.organizations.errors.patronage.payment_schedules.is_different",
								{
									paymentSchedulesTotal: getPrice(paymentSchedulesTotal),
									donationAmount: getPrice(donationAmount)
								}
							)}
						</Alert>
					)}
				</Box>
			</Drawer>
		</>
	)
}

export default PatronagePaymentSchedulesForm
