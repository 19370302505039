import React, { useEffect, useState } from "react"
import {
	Edit,
	FormTab,
	SaveButton,
	ShowButton,
	TabbedForm,
	Toolbar,
	TopToolbar,
	useNotify,
	useRedirect,
	useRefresh
} from "react-admin"
import BarChartIcon from "@mui/icons-material/BarChart"

import RecipientTab from "./RecipientTab"
import DetailTab from "./DetailTab"
import EmailTab from "./EmailTab"
import { prepareData } from "./prepareData"
import FormTabWithBadge from "utils/FormTabWithBadge"
import { fetchTemplatesData } from "utils/mailjet"
import { ITemplateData } from "./interfaces/i-template-data"
import { validateEvent } from "./validate-event"

const EventEditToolbar = () => <Toolbar>
	<SaveButton alwaysEnable={true} />
</Toolbar>

const Actions = () => <TopToolbar>
	<ShowButton
		label="resources.events.statistics.name"
		icon={<BarChartIcon/>}
	/>
</TopToolbar>

const EventEdit = () => {
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onSuccess = (data: any) => {
		notify(
			"resources.events.notifications.edit",
			{
				type: "info",
				messageArgs: { name: data.name }
			}
		)
		redirect("/events")
		refresh()
	}

	const [templatesData, setTemplatesData] = useState<ITemplateData[]>()

	useEffect(
		() => {
			const init = async () => {
				setTemplatesData(await fetchTemplatesData())
			}

			init()
		},
		[]
	)

	return (
		<Edit
			mutationOptions={{ onSuccess }}
			mutationMode="pessimistic"
			transform={prepareData}
			title="resources.events.edit"
			actions={<Actions/>}
		>
			<TabbedForm
				toolbar={<EventEditToolbar />}
				validate={(values: any) => validateEvent(values, true)}
			>
				<FormTab label="resources.events.details.name">
					<DetailTab isEdit />
				</FormTab>

				<FormTabWithBadge label="resources.events.recipients.name" badge="eventContacts">
					<RecipientTab enableResponse={true} />
				</FormTabWithBadge>

				<FormTab label="resources.events.email.name">
					<EmailTab templatesData={templatesData} type="email" />
				</FormTab>

				<FormTab label="resources.events.follow_up.name">
					<EmailTab templatesData={templatesData} type="follow_up" />
				</FormTab>

			</TabbedForm>
		</Edit>
	)
}


export default EventEdit
