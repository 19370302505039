/* eslint-disable */
import * as React from "react"
import { useState } from "react"
import Box from "@mui/material/Box"

import {
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from "react-admin"

import PersonIcon from "@mui/icons-material/Person"
import WorkIcon from "@mui/icons-material/Work"
import GroupIcon from "@mui/icons-material/Group"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import EmailIcon from "@mui/icons-material/Email"
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks"
import BusinessIcon from "@mui/icons-material/Business"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

import { isPlatform } from "utils/api"

import SubMenu from "./SubMenu"

type MenuName = "menuAdmin" | "menuDirectory" | "menuEvents"

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuDirectory: true,
        menuEvents: false,
        menuAdmin: false,
    })
    const [open] = useSidebarState()

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }))
    }

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    })
            }}
        >
            <DashboardMenuItem />
            <SubMenu
                handleToggle={() => handleToggle("menuDirectory")}
                isOpen={state.menuDirectory}
				name="categories.directory"
                icon={<ChevronRightIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/contacts"
                    state={{ _scrollToTop: true }}
					primaryText="resources.contacts.name"
                    leftIcon={<GroupIcon />}
                    dense={dense}
                />
				<MenuItemLink
					to={{ pathname: "/groups" }}
					primaryText="resources.groups.name"
					leftIcon={<GroupAddIcon />}
					dense={dense}
				/>
				<MenuItemLink
					to={{ pathname: "/organizations" }}
					primaryText="resources.organizations.name"
					leftIcon={<BusinessIcon />}
					dense={dense}
				/>
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuEvents")}
                isOpen={state.menuEvents}
                name="categories.events"
                icon={<ChevronRightIcon />}
                dense={dense}
            >
				{isPlatform("trelaze") && (
					<MenuItemLink
						to={{ pathname: "/events" }}
						primaryText="resources.events.name"
						leftIcon={<CalendarMonthIcon />}
						dense={dense}
					/>
				)}
				{isPlatform("arena") && (
					<MenuItemLink
						to={{ pathname: "/partner-events" }}
						primaryText="resources.partner_events.name"
						leftIcon={<PermContactCalendarIcon />}
						dense={dense}
					/>
				)}
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle("menuAdmin")}
                isOpen={state.menuAdmin}
                name="categories.administration"
                icon={<ChevronRightIcon />}
                dense={dense}
            >
				<MenuItemLink
					to={{ pathname: "/users" }}
					primaryText="resources.users.name"
					leftIcon={<PersonIcon />}
					dense={dense}
				/>
				<MenuItemLink
					to={{ pathname: "/jobs" }}
					primaryText="resources.jobs.name"
					leftIcon={<WorkIcon />}
					dense={dense}
				/>
				<MenuItemLink
					to={{ pathname: "/email-addresses" }}
					primaryText="resources.email_addresses.name"
					leftIcon={<EmailIcon />}
					dense={dense}
				/>
				{isPlatform("arena") && (
					<MenuItemLink
						to={{ pathname: "/patronage-packs" }}
						primaryText="resources.patronage_packs.name"
						leftIcon={<LibraryBooksIcon />}
						dense={dense}
					/>
				)}
            </SubMenu>
        </Box>
    )
}

export default Menu
