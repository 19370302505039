/* eslint-disable */
const emailAddressesMessages = {
	resources: {
		email_addresses: {
			create: "Ajouter une adresse",
			edit: "Modifier une adresse",
			name: "Adresses",
			title: "Liste des adresses",
			fields: {
				name: "Nom",
				email: "Email",
				created_at: "Créée le",
				updated_at: "Mise à jour le"
			},
			notifications: {
				create: "Adresse %{name} créée",
				edit: "Addresse %{name} modifiée"
			}
		}
	}
}

export default emailAddressesMessages
