/* eslint-disable prefer-reflect */
export const prepareData = (data: any) => {
	delete data.orgQuery

	if (data.email && data.email.name === "") {
		data.email = null
	}

	if (data.organizations === undefined) {
		data.organizations = []
	}

	return data
}
