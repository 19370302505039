import React from "react"
import { get, isArray } from "lodash"
import { FormDataConsumer, FormTab, FormTabProps, useTranslate } from "react-admin"
import { Chip } from "@mui/material"

interface FormTabWithBadgeProps extends FormTabProps {
	badge: string
	label: string
}

const FormTabWithBadge = ({ label, badge, ...props }: FormTabWithBadgeProps) => <FormDataConsumer>
	{({ formData }) => {
		const translate = useTranslate()

		const badgeData = get(formData, badge)
		const labelTranslate = translate(label)
		let labelBadge: string | JSX.Element = labelTranslate

		if (isArray(badgeData) && badgeData.length > 0) {
			labelBadge = <span>
				{labelTranslate}
				<Chip
					label={ badgeData.length }
					sx={{
						marginLeft: ".5em",
						marginTop: "-.4em",
						marginBottom: "-.4em",
						fontSize: ".85em"
					}}
					size="small"
				/>
			</span>
		}

		return <FormTab label={labelBadge} {...props} />
	}}
</FormDataConsumer>

export default FormTabWithBadge
