import React, { createContext, useContext, useEffect, useState } from "react"
import Cookies from "js-cookie"

import { getUrl } from "../utils/api"

interface INoticeContext {
	countOfUnread: number,
	reduceCount: () => void
}

const defaultNoticeContext: INoticeContext = {
	countOfUnread: 0,
	reduceCount: () => null
}

const NoticeContext = createContext(defaultNoticeContext)

export const useNotice = () => {
	return useContext(NoticeContext)
}

const useNoticeProvider = () => {
	const [countOfUnread, setCountOfUnread] = useState(0)

	useEffect(
		() => {
			if (Cookies.get("token") === undefined) {
				return
			}

			const initCountOfUnread = async () => {
				const response = await fetch(getUrl("/notices/count-of-unread"), {
					headers: new Headers({
						"Content-Type": "application/json",
						Authorization: `Bearer ${Cookies.get("token")}`
					}),
					method: "GET"
				})

				const count = await response.json()

				setCountOfUnread(count)
			}

			initCountOfUnread()
		},
		[]
	)

	const reduceCount = () => setCountOfUnread(countOfUnread - 1)

	return {
		countOfUnread,
		reduceCount
	}
}

export const NoticeProvider = (props: any) => {
	const data = useNoticeProvider()

	return (
		<NoticeContext.Provider value={data}>
			{props.children}
		</NoticeContext.Provider>
	)
}
