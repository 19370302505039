import React from "react"
import { useTranslate } from "react-admin"
import { SxProps, Typography, Theme } from "@mui/material"

export const SectionTitle = ({ label, sx }: { label: string, sx?: SxProps<Theme> }) => {
	const translate = useTranslate()

	return (
		<Typography variant="h6" gutterBottom sx={sx}>
			{translate(label)}
		</Typography>
	)
}
