/* eslint-disable no-console, max-len, max-lines-per-function, @typescript-eslint/no-explicit-any */
import React from "react"
import {
	BooleanInput,
	Edit,
	RadioButtonGroupInput,
	TabbedForm,
	FormTab,
	TextInput,
	required,
	useNotify,
	useRedirect,
	useRefresh,
	ArrayInput,
	SimpleFormIterator
} from "react-admin"
import { Box } from "@mui/material"

import ContactAddressForm from "./ContactAddressForm"
import { validateContactOrganization } from "./validate-contact-organization"

import sx from "utils/sx"
import { SectionTitle } from "utils/SectionTitle"
import FormTabWithBadge from "utils/FormTabWithBadge"

const ContactEdit = () => {
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	const onSuccess = (data: any) => {
		notify(
			"resources.contacts.notifications.edit",
			{
				type: "info",
				messageArgs: {
					firstName: data.firstName,
					lastName: data.lastName
				}
			}
		)
		redirect("/contacts")
		refresh()
	}

	return (
		<Edit
			mutationOptions={{ onSuccess }}
			mutationMode="pessimistic"
			title="resources.contacts.edit"
		>
			<TabbedForm
				validate={(values: any) => validateContactOrganization(values)}
			>
				<FormTab label="resources.contacts.titles.information">
					<Box sx={sx.prose}>
						<RadioButtonGroupInput
							source="civility"
							label="resources.contacts.fields.civility"
							validate={required()}
							choices={[
								{ id: 1, name: "resources.contacts.fields.civilities.madam" },
								{ id: 2, name: "resources.contacts.fields.civilities.mister" }
							]}
						/>
						<TextInput
							label="resources.contacts.fields.first_name"
							source="firstName"
							validate={required()}
							fullWidth
						/>

						<TextInput
							label="resources.contacts.fields.last_name"
							source="lastName"
							validate={required()}
							fullWidth
						/>
						<BooleanInput
							label="resources.groups.fields.is_archived"
							source="isArchived"
						/>
					</Box>
				</FormTab>

				<FormTab label="resources.contacts.titles.contact">
					<ContactAddressForm source="individual" />
				</FormTab>

				<FormTabWithBadge label="resources.contacts.titles.organizations" badge="contactOrganizations">
					<SectionTitle label="resources.contacts.titles.isContactForOrganizations" />
					<ArrayInput source="contactOrganizations" label="">
						<SimpleFormIterator disableReordering>
							<ContactAddressForm />
						</SimpleFormIterator>
					</ArrayInput>
				</FormTabWithBadge>
			</TabbedForm>
		</Edit>
	)
}

export default ContactEdit
