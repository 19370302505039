import React from "react"
import {
	Button,
	CreateButton,
	Datagrid,
	DateField,
	EditButton,
	FunctionField,
	List,
	ShowButton,
	TextField,
	TopToolbar,
	useRecordContext,
	useTranslate
} from "react-admin"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import BarChartIcon from "@mui/icons-material/BarChart"
import DownloadIcon from "@mui/icons-material/Download"

import sx from "utils/sx"
import { getUrl } from "utils/api"
import renderNumberChipField from "utils/renderNumberChipField"

const Actions = () => <TopToolbar>
	<CreateButton label="misc.create" />
</TopToolbar>

const GeneratePdfAction = () => {
	const translate = useTranslate()
	const record = useRecordContext()

	return <>
		{record && record.hasLabels
			? <Button
				href={getUrl(`/events/labels/${record.id}`)}
				onClick={(event) => event.stopPropagation() }
				title={translate("resources.events.export_addresses")}
				startIcon={<PictureAsPdfIcon />}
				sx={sx.datagridButton}
				size="large"
				{ ...{ target: "_blank" } }
			/>
			: null
		}
	</>
}

const ExportAction = () => {
	const translate = useTranslate()
	const record = useRecordContext()

	return <>
		{record
			? <Button
				href={getUrl(`/events/${record.id}/export`)}
				onClick={(event) => event.stopPropagation() }
				title={translate("resources.events.export_contacts")}
				startIcon={<DownloadIcon />}
				sx={sx.datagridButton}
				size="large"
				{ ...{ target: "_blank" } }
			/>
			: null
		}
	</>
}
const EventsList = () => {

	return <List
		actions={<Actions />}
		title="resources.events.title"
	>
		<Datagrid
			rowClick="edit"
			bulkActionButtons={false}
			sx={sx.datagridColumns}
		>
			<TextField
				source="name"
				label="resources.events.details.fields.name"
			/>
			<DateField
				showTime
				options={{ dateStyle: "short", timeStyle: "short" }}
				source="startTime"
				label="resources.events.details.fields.start_time"
			/>
			<DateField
				showTime
				options={{ dateStyle: "short", timeStyle: "short" }}
				source="endTime"
				label="resources.events.details.fields.end_time"
			/>
			<FunctionField
				headerClassName="column-number"
				cellClassName="column-number"
				label="resources.events.recipients.name"
				source="eventContacts"
				render={renderNumberChipField}
				sortable={false}
			/>
			<DateField
				showTime
				options={{ dateStyle: "short", timeStyle: "short" }}
				headerClassName="column-email-plannedAt"
				cellClassName="column-email-plannedAt"
				source="email.plannedAt"
				label="resources.events.email.fields.planned_at"
				emptyText="—"
				sortable={false}
			/>
			<DateField
				showTime
				options={{ dateStyle: "short", timeStyle: "short" }}
				headerClassName="column-followUp-plannedAt"
				cellClassName="column-followUp-plannedAt"
				source="followUp.plannedAt"
				label="resources.events.follow_up.name"
				emptyText="—"
				sortable={false}
			/>
			<EditButton
				label=""
				size="large"
				sx={sx.datagridButton}
			/>
			<ShowButton
				label=""
				size="large"
				startIcon={<BarChartIcon />}
				sx={sx.datagridButton}
			/>
			<GeneratePdfAction />
			<ExportAction />
		</Datagrid>
	</List>
}

export default EventsList
