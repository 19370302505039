/* eslint-disable implicit-arrow-linebreak */
import React from "react"
import { useGetList } from "react-admin"
import { List } from "@mui/material"

import ListItem from "./ListItem"
import Empty from "./Empty"

const PartnerEventsList = () => {
	const { data: partnerEvents } = useGetList("partner-events", {
		filter: { future: true },
		sort: { field: "startTime", order: "ASC" },
		pagination: { page: 1, perPage: 10 }
	})

	if (!partnerEvents) {
		return null
	}

	if (partnerEvents.length === 0) {
		return <Empty />
	}

	return (
		<List disablePadding>
			{partnerEvents.map((partnerEvent) => <ListItem
				key={partnerEvent.id}
				partnerEvent={partnerEvent}
			/>)}
		</List>
	)
}

export default PartnerEventsList
