import React from "react"
import {
	CreateButton,
	Datagrid,
	DateField,
	EditButton,
	FunctionField,
	List,
	ShowButton,
	TextField,
	TopToolbar
} from "react-admin"
import BarChartIcon from "@mui/icons-material/BarChart"

import sx from "utils/sx"
import renderNumberChipField from "utils/renderNumberChipField"

const Actions = () => <TopToolbar>
	<CreateButton label="misc.create" />
</TopToolbar>

const PartnerEventsList = () => {

	return <List
		actions={<Actions />}
		title="resources.partner_events.title"
	>
		<Datagrid
			rowClick="edit"
			bulkActionButtons={false}
			sx={sx.datagridColumns}
		>
			<TextField
				source="name"
				label="resources.partner_events.details.fields.name"
			/>
			<DateField
				showTime
				options={{ dateStyle: "short", timeStyle: "short" }}
				source="startTime"
				label="resources.partner_events.details.fields.start_time"
			/>
			<DateField
				showTime
				options={{ dateStyle: "short", timeStyle: "short" }}
				source="endTime"
				label="resources.partner_events.details.fields.end_time"
			/>
			<FunctionField
				headerClassName="column-number"
				cellClassName="column-number"
				label="resources.partner_events.details.fields.patronage_count"
				source="partnerEventPatronages"
				render={renderNumberChipField}
				sortable={false}
			/>
			<DateField
				showTime
				options={{ dateStyle: "short", timeStyle: "short" }}
				headerClassName="column-email-plannedAt"
				cellClassName="column-email-plannedAt"
				source="email.plannedAt"
				label="resources.partner_events.email.fields.planned_at"
				emptyText="—"
				sortable={false}
			/>
			<EditButton
				label=""
				size="large"
				sx={sx.datagridButton}
			/>
			<ShowButton
				label=""
				size="large"
				startIcon={<BarChartIcon />}
				sx={sx.datagridButton}
			/>
		</Datagrid>
	</List>
}

export default PartnerEventsList
