/* eslint-disable consistent-return, max-lines-per-function */
import React, { useState } from "react"
import {
	Box,
	Button,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText
} from "@mui/material"
import {
	useTranslate,
	useGetList,
	Datagrid,
	TextField,
	NumberField,
	useRecordContext,
	FunctionField,
	useList,
	ListContextProvider
} from "react-admin"
import { useFormContext } from "react-hook-form"
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks"
import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"

import sx from "utils/sx"
import renderNumberChipField from "utils/renderNumberChipField"

interface PatronagePacksProps {
	source: string
	toogleDrawer: () => void
}

interface PatronagePacksFormProps {
	source: string
}

const SelectButton = ({ source, toogleDrawer }: PatronagePacksProps) => {
	const translate = useTranslate()
	const record = useRecordContext()
	const { getValues, setValue } = useFormContext()

	const formValues = getValues(source)
	const isSelected = record.price === formValues.amount && record.ticketNumbers === formValues.tickets.total

	const handleSelect = () => {
		setValue(`${source}.amount`, record.price)
		setValue(`${source}.tickets.total`, record.ticketNumbers)
		toogleDrawer()
	}

	return (
		<Button
			size="small"
			variant="contained"
			onClick={handleSelect}
			color="primary"
			disabled={isSelected}
			sx={{ marginY: 1 }}
		>
			{isSelected
				? translate("resources.organizations.titles.patronage.packs.selected_button")
				: translate("resources.organizations.titles.patronage.packs.select_button")
			}
		</Button>
	)
}

const PatronagePacks = ({ source, toogleDrawer }: PatronagePacksProps) => {
	const translate = useTranslate()
	const { data, isLoading } = useGetList("patronage-packs", {
		pagination: { page: 1, perPage: 100 },
		sort: { field: "price", order: "ASC" }
	})
	const listContext = useList({ data, isLoading })

	if (!isLoading && data && data.length === 0) {
		return (
			<Button
				size="small"
				fullWidth
				variant="contained"
				href="/#/patronage-packs/create"
				color="primary"
				startIcon={<AddIcon/>}
				sx={{ marginTop: 2 }}
			>
				{translate("resources.organizations.titles.patronage.packs.add")}
			</Button>
		)
	}

	return (
		<ListContextProvider value={listContext}>
			<Datagrid
				bulkActionButtons={false}
				sx={sx.datagridColumns}
			>
				<TextField
					source="name"
					label="resources.patronage_packs.fields.name"
				/>
				<NumberField
					source="price"
					options={{ style: "currency", currency: "EUR" }}
					label="resources.patronage_packs.fields.price"
				/>
				<FunctionField
					headerClassName="column-number"
					cellClassName="column-number"
					source="ticketNumbers"
					label="resources.patronage_packs.fields.ticket_numbers"
					render={renderNumberChipField}
				/>
				<SelectButton
					source={source}
					toogleDrawer={toogleDrawer}
				/>
			</Datagrid>
		</ListContextProvider>
	)
}

const PatronagePacksForm = ({ source }: PatronagePacksFormProps) => {
	const translate = useTranslate()
	const [hasDrawer, setHasDrawer] = useState(false)

	const toogleDrawer = () => setHasDrawer(!hasDrawer)

	return (
		<>
			<Button
				variant="outlined"
				color="primary"
				startIcon={<LibraryBooksIcon />}
				sx={{ marginBottom: 4 }}
				onClick={() => setHasDrawer(true)}
			>
				{translate("resources.organizations.titles.patronage.packs.button")}
			</Button>
			<Drawer
				anchor="right"
				open={hasDrawer}
				onClose={() => toogleDrawer()}
				sx={{ "& .MuiDrawer-paper": { width: "60ch", maxWidth: "100%" } }}
			>
				<List disablePadding>
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => toogleDrawer()}
						>
							<ListItemIcon>
								<CloseIcon />
							</ListItemIcon>
							<ListItemText
								primary={translate("resources.patronage_packs.name")}
								primaryTypographyProps={{ fontWeight: "bold", color: "primary" }}
							/>
						</ListItemButton>
					</ListItem>
				</List>
				<Divider />
				<Box sx={{ marginX: 2 }}>
					<PatronagePacks
						source={source}
						toogleDrawer={toogleDrawer}
					/>
				</Box>
			</Drawer>
		</>
	)
}

export default PatronagePacksForm
