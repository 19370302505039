/* eslint-disable */
const groupsMessages = {
	resources: {
		groups: {
			create: "Ajouter un groupe",
			edit: "Modifier un groupe",
			name: "Groupes",
			title: "Liste des groupes",
			fields: {
				name: "Nom",
				description: "Description",
				created_at: "Créé le",
				updated_at: "Mis à jour le",
				is_archived: "Archivé",
				export: "Exporter les contacts du groupe"
			},
			notifications: {
				create: "Groupe %{name} créé",
				edit: "Groupe %{name} modifié"
			},
			filters: {
				is_archived: "Afficher les groupes archivés"
			}
		}
	}
}

export default groupsMessages
