import dayjs from "dayjs"
import React from "react"
import {
	BooleanField,
	BooleanInput,
	CreateButton,
	Datagrid,
	DateField,
	EditButton,
	FunctionField,
	List,
	SearchInput,
	TextField,
	TopToolbar,
	useTranslate
} from "react-admin"

import { getSeasonTitle } from "utils/patronages"

import sx from "utils/sx"

const listFilters = [
	<SearchInput
		source="name"
		alwaysOn
		resettable
	/>,
	<BooleanInput
		label="resources.organizations.filters.is_archived"
		source="isArchived"
		alwaysOn
	/>,
	<BooleanInput
		label="resources.organizations.filters.has_patronages"
		source="hasPatronages"
		alwaysOn
	/>
]

const Actions = () => <TopToolbar>
	<CreateButton label="misc.create" />
</TopToolbar>

const OrganizationsList = () => {
	return <List
		actions={<Actions />}
		title="resources.organizations.title"
		filters={listFilters}
		sort={{ field: "name", order: "ASC" }}
	>
		<Datagrid
			rowClick="edit"
			bulkActionButtons={false}
			sx={sx.datagridColumns}
		>
			<TextField
				source="name"
				label="resources.organizations.fields.name"
			/>
			<TextField
				source="phoneNumber"
				label="resources.organizations.fields.phone_number"
				sx={{ whiteSpace: "nowrap" }}
			/>
			<TextField
				source="email"
				label="resources.organizations.fields.email"
			/>
			<DateField
				source="createdAt"
				label="resources.organizations.fields.created_at"
			/>
			<DateField
				source="updatedAt"
				label="resources.organizations.fields.updated_at"
			/>
			<FunctionField
				label="resources.organizations.fields.has_patronage"
				source="patronages"
				render={({ patronages }: any) => {
					const translate = useTranslate()
					const patronage = patronages.find((item: any) => (
						dayjs().isBetween(item.startTime, item.endTime, "day", "[]")
					))

					return (
						<BooleanField
							record={{ hasPatronage: !!patronage }}
							source="hasPatronage"
							valueLabelTrue={patronage ? getSeasonTitle(patronage, translate) : undefined}
						/>
					)
				}}
				sortable={false}
			/>
			<BooleanField
				source="isArchived"
				label="resources.organizations.fields.is_archived"
				sortable={false}
			/>
			<EditButton
				label=""
				size="large"
				sx={sx.datagridButton}
			/>
		</Datagrid>
	</List>
}

export default OrganizationsList
