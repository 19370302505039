/* eslint-disable */
const patronagePacksMessages = {
	resources: {
		patronage_packs: {
			create: "Ajouter un nouveau pack de mécénat",
			edit: "Modifier un pack de mécénat",
			name: "Packs de mécénat",
			title: "Liste des packs de mécénat",
			fields: {
				name: "Nom",
				price: "Prix",
				ticket_numbers: "Places",
				created_at: "Créé le",
				updated_at: "Mis à jour le"
			},
			notifications: {
				create: "Pack de mécénat %{name} crée",
				edit: "Pack de mécénat %{name} modifié"
			}
		}
	}
}

export default patronagePacksMessages
