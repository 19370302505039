/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import { get, isUndefined } from "lodash"
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Box,
	Button,
	CircularProgress,
	Typography
} from "@mui/material"
import { useFormContext } from "react-hook-form"
import {
	ArrayInput,
	DateInput,
	NumberInput,
	RaRecord,
	required,
	SelectInput,
	SimpleFormIterator,
	useRecordContext,
	useTranslate
} from "react-admin"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"

import FormGrid from "utils/FormGrid"
import { SectionTitle } from "utils/SectionTitle"
import sx from "utils/sx"
import { getUrl } from "utils/api"
import { getSeasonTitle } from "utils/patronages"

import PatronageContactForm from "./PatronageContactForm"
import PatronagePaymentSchedulesForm from "./PatronagePaymentSchedulesForm"
import PatronagePacksForm from "./PatronagePacksForm"
import PatronageAttachmentForm from "./PatronageAttachmentForm"

const GeneratePdfAction = ({ patronage }: { patronage: any }) => {
	const translate = useTranslate()

	const isEnabled = !isUndefined(patronage)
		&& patronage.startTime
		&& patronage.endTime
		&& patronage.amount
		&& patronage.tickets
		&& patronage.tickets.total
		&& patronage.signer.contactOrganizationId

	return (
		<Button
			variant="contained"
			href={getUrl(`/patronages/${patronage?.id}/agreement`)}
			startIcon={<PictureAsPdfIcon />}
			size="large"
			disabled={!isEnabled}
			{...{ target: "_blank" }}
			sx={{ marginBottom: 2 }}
		>
			{translate("resources.organizations.titles.patronage.download_agreement")}
		</Button>
	)
}

interface PatronageFormProps {
	source?: any
	expanded: number
	setExpanded: React.Dispatch<React.SetStateAction<number>>
	contactOrganizations: any[]
	isEdit?: boolean
}

const PatronageForm = ({
	source,
	expanded,
	setExpanded,
	contactOrganizations,
	isEdit = false
}: PatronageFormProps) => {
	const { getValues, setValue } = useFormContext()
	const record = useRecordContext()
	const translate = useTranslate()

	const index = Number(source.replace("patronages.", ""))
	const values = getValues(source) || {}

	useEffect(
		() => {
			setExpanded(index)
		},
		[]
	)

	const handleChangeStartTime: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		if (!values.endTime) {
			setValue(
				`${source}.endTime`,
				dayjs(event.target.value)
					.add(1, "year")
					.subtract(1, "second")
			)
		}
	}

	const handleChangeType = (event: React.ChangeEvent<HTMLInputElement> | RaRecord): void => {
		if (event.target.value !== "1") {
			setValue(`${source}.amount`, undefined)
		}
	}

	const selectType = (
		<SelectInput
			label="resources.organizations.fields.patronage.type.name"
			source={`${source}.type`}
			validate={required()}
			choices={[
				{ id: 1, name: "resources.organizations.fields.patronage.type.options.numerary" },
				{ id: 2, name: "resources.organizations.fields.patronage.type.options.material" }
			]}
			onChange={handleChangeType}
			fullWidth
		/>
	)

	return (
		<Accordion
			expanded={expanded === index}
			onChange={() => setExpanded(index)}
			disableGutters
			square
			elevation={0}
			sx={{ width: "100%" }}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls={`panel${index}-content`}
				id={`panel${index}-header`}
				sx={{ flexDirection: "row-reverse" }}
			>
				<Typography sx={{ fontWeight: "bold" }} color="primary">
					{getSeasonTitle(values, translate)}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box sx={sx.prose}>
					<SectionTitle label="resources.organizations.titles.patronage.season" />
					<FormGrid>
						<DateInput
							label="resources.organizations.fields.patronage.start_time"
							source={`${source}.startTime`}
							validate={required()}
							onChange={handleChangeStartTime}
							fullWidth
						/>
						<DateInput
							label="resources.organizations.fields.patronage.end_time"
							source={`${source}.endTime`}
							validate={required()}
							fullWidth
						/>
					</FormGrid>

					<SectionTitle label="resources.organizations.titles.patronage.donation" />
					{values.type !== 1 && selectType}
					{values.type === 1 && (
						<>
							<FormGrid>
								<div>
									{selectType}
									<PatronagePacksForm
										source={source}
									/>
								</div>
								<div>
									<NumberInput
										label="resources.organizations.fields.patronage.amount"
										source={`${source}.amount`}
										validate={required()}
										type="number"
										step={0.1}
										fullWidth
									/>
									<PatronagePaymentSchedulesForm
										source={source}
										seasonTitle={getSeasonTitle(values, translate)}
									/>
								</div>
							</FormGrid>
						</>
					)}

					<SectionTitle label="resources.organizations.titles.patronage.tickets" />
					<FormGrid columnWidths={[6]}>
						<NumberInput
							label="resources.organizations.fields.patronage.tickets.total"
							source={`${source}.tickets.total`}
							validate={required()}
							type="number"
							inputProps={{
								min: 0
							}}
							fullWidth
						/>
					</FormGrid>

					<SectionTitle label="resources.organizations.titles.patronage.signer" />
					<PatronageContactForm
						source={`${source}.signer`}
						contactOrganizations={contactOrganizations}
						isRequired
					/>

					<SectionTitle label="resources.organizations.titles.patronage.contacts" />
					<PatronageContactForm
						source={`${source}.contacts.0`}
						contactOrganizations={contactOrganizations}
					/>
					<PatronageContactForm
						source={`${source}.contacts.1`}
						contactOrganizations={contactOrganizations}
					/>

					<SectionTitle label="resources.organizations.titles.patronage.attachments" />
					<PatronageAttachmentForm
						sectionTitle="Contrat de mécénat"
						source={`${source}.attachments.contract`}
					/>
					<PatronageAttachmentForm
						sectionTitle="Convention de mécénat"
						source={`${source}.attachments.convention`}
					/>

					{isEdit && (
						<>
							<SectionTitle label="resources.organizations.titles.patronage.agreement" />
							<GeneratePdfAction
								patronage={get(record, source)}
							/>
						</>
					)}
				</Box>
			</AccordionDetails>
		</Accordion>
	)
}

const PatronagesForm = ({ isEdit = false }) => {
	const translate = useTranslate()
	const [expanded, setExpanded] = React.useState(-1)
	const record = useRecordContext()
	const [contactOrganizations, setContactOrganizations] = useState<any[] | null>(null)

	useEffect(
		() => {
			setContactOrganizations(record.contactOrganizations.filter((contact: any) => !!contact.email))
		},
		[]
	)

	if (contactOrganizations === null) {
		return (
			<Typography sx={{ display: "flex", alignItems: "center" }}>
				<CircularProgress size={20} sx={{ marginRight: 1 }} />
				{translate("resources.organizations.titles.patronage.contacts_loading")}
			</Typography>
		)
	}

	if (contactOrganizations.length === 0) {
		return (
			<Alert severity="error">
				{translate("resources.organizations.errors.patronage.contacts")}
			</Alert>
		)
	}

	return (
		<ArrayInput source="patronages" label="">
			<SimpleFormIterator disableReordering>
				<PatronageForm
					expanded={expanded}
					setExpanded={setExpanded}
					contactOrganizations={contactOrganizations}
					isEdit={isEdit}
				/>
			</SimpleFormIterator>
		</ArrayInput>
	)
}

export default PatronagesForm
