import React from "react"
import { Box } from "@mui/material"
import {
	BooleanInput,
	Edit,
	required,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh
} from "react-admin"

import sx from "utils/sx"

const GroupEdit = () => {
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onSuccess = (data: any) => {
		notify(
			"resources.groups.notifications.edit",
			{
				type: "info",
				messageArgs: { name: data.name }
			}
		)
		redirect("/groups")
		refresh()
	}

	return (
		<Edit
			mutationOptions={{ onSuccess }}
			mutationMode="pessimistic"
			title="resources.groups.edit"
		>
			<SimpleForm>
				<Box sx={sx.prose}>
					<TextInput
						source="name"
						label="resources.groups.fields.name"
						validate={required()}
						fullWidth
					/>
					<TextInput
						label="resources.groups.fields.description"
						source="description"
						validate={required()}
						multiline
						fullWidth
					/>
					<BooleanInput
						label="resources.groups.fields.is_archived"
						source="isArchived"
					/>
				</Box>
			</SimpleForm>
		</Edit>
	)
}


export default GroupEdit
