/* eslint-disable complexity, no-warning-comments, max-lines-per-function, @typescript-eslint/no-explicit-any */
import { isEmpty, omit } from "lodash"
import { emailValidator } from "utils"

const validateSendType = (values: any) => {
	const errors: any = {}

	if (!values.sendType) {
		errors.sendType = "errors.required_value"
	}

	// Email address fill validation
	if (Number(values.sendType) === 1) {
		if (!values.email) {
			errors.email = "resources.contacts.errors.send_type_email"
		}

		if (values.email && !emailValidator.test(values.email)) {
			errors.email = "errors.invalid_email_address"
		}
	}

	// Physical address validation
	if (Number(values.sendType) === 2) {
		const errorsAddress: any = {}

		if (values.address) {
			if (!values.address.address1) {
				errorsAddress.address1 = "resources.contacts.errors.send_type_address"
			}

			if (!values.address.city) {
				errorsAddress.city = "resources.contacts.errors.send_type_city"
			}

			if (!values.address.zipCode) {
				errorsAddress.zipCode = "resources.contacts.errors.send_type_zip_code"
			}
		} else {
			errorsAddress.address1 = "resources.contacts.errors.send_type_address"
			errorsAddress.city = "resources.contacts.errors.address"
			errorsAddress.zipCode = "resources.contacts.errors.address"
		}

		if (!isEmpty(errorsAddress)) {
			errors.address = errorsAddress
		}
	}

	return errors
}

export const validateContactOrganization = (values: any) => {
	let errors: any = {}

	if (!values.civility) {
		errors.civility = "errors.required_value"
	}

	if (!values.firstName) {
		errors.firstName = "errors.required_value"
	}

	if (!values.lastName) {
		errors.lastName = "errors.required_value"
	}

	const individual = {
		...omit(values.individual, ["address", "groupIds", "jobId"]),
		...values.individual.address
	}

	const isFilled = Object.values(individual).some((value) => !!value)

	if (isFilled === true) {
		const errorsIndividualSendType = validateSendType(values.individual)

		if (!isEmpty(errorsIndividualSendType)) {
			errors = {
				...errors,
				individual: errorsIndividualSendType
			}
		}
	}

	/* --------- Looping on each address form for organization/contact ------------- */

	if (values.contactOrganizations && values.contactOrganizations.length > 0) {
		let errorsContactOrganizationCount = 0
		const errorsContactOrganizations: any[] = []

		values.contactOrganizations.forEach((organization: any) => {
			const errorsContactOrganization = validateSendType(organization)

			errorsContactOrganizations.push(errorsContactOrganization)

			if (!isEmpty(errorsContactOrganization)) {
				errorsContactOrganizationCount++
			}
		})

		if (errorsContactOrganizationCount > 0) {
			errors = {
				...errors,
				contactOrganizations: errorsContactOrganizations
			}
		}
	}

	return errors
}
