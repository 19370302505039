import React from "react"
import {
	CreateButton,
	Datagrid,
	DateField,
	EditButton,
	List,
	TextField,
	TopToolbar
} from "react-admin"

import sx from "utils/sx"

const Actions = () => <TopToolbar>
	<CreateButton label="misc.create" />
</TopToolbar>

const UsersList = () => {
	return (
		<List
			actions={<Actions />}
			title="resources.users.title"
		>
			<Datagrid
				rowClick="edit"
				bulkActionButtons={false}
				sx={sx.datagridColumns}
			>
				<TextField
					source="firstName"
					label="resources.users.fields.first_name"
				/>
				<TextField
					source="lastName"
					label="resources.users.fields.last_name"
				/>
				<TextField
					source="email"
					label="resources.users.fields.email"
				/>
				<DateField
					source="createdAt"
					label="resources.users.fields.created_at"
				/>
				<DateField
					source="createdAt"
					label="resources.users.fields.updated_at"
				/>
				<EditButton
					label=""
					size="large"
					sx={sx.datagridButton}
				/>
			</Datagrid>
		</List>
	)
}

export default UsersList
