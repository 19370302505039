/* eslint-disable */
const jobsMessages = {
	resources: {
		jobs: {
			create: "Ajouter une fonction",
			edit: "Modifier une fonction",
			name: "Fonctions",
			title: "Liste des fonctions",
			fields: {
				name: "Nom",
				created_at: "Créée le",
				updated_at: "Mise à jour le"
			},
			notifications: {
				create: "Fonction %{name} créée",
				edit: "Fonction %{name} modifiée"
			}
		}
	}
}

export default jobsMessages
