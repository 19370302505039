import React from "react"
import {
	BooleanInput,
	required,
	SelectInput
} from "react-admin"

import FormGrid from "utils/FormGrid"

interface PatronageContactFormProps {
	source: string
	isRequired?: boolean
	contactOrganizations: any[]
}

const PatronageContactForm = ({ source, isRequired = false, contactOrganizations }: PatronageContactFormProps) => (
	<FormGrid>
		<SelectInput
			label="resources.organizations.fields.patronage.contacts.contact_organization_id"
			source={`${source}.contactOrganizationId`}
			validate={isRequired ? required() : undefined}
			optionText="email"
			choices={contactOrganizations}
			fullWidth
		/>
		<BooleanInput
			label="resources.organizations.fields.patronage.contacts.is_recipient"
			source={`${source}.isRecipient`}
			sx={{ marginTop: 2 }}
		/>
	</FormGrid>
)

export default PatronageContactForm
