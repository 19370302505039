/* eslint-disable prefer-reflect */
export const prepareData = (data: any) => {
	delete data.searchQuery
	delete data.orgQuery
	delete data.searchQuery

	if (data.email && data.email.name === "") {
		data.email = null
	}

	if (data.followUp && data.followUp.name === "") {
		data.followUp = null
	}

	if (data.eventContacts === undefined) {
		data.eventContacts = []
	} else {
		data.eventContacts = data.eventContacts.map((eventContact: any) => ({
			id: eventContact.id,
			response: eventContact.response,
			guestCount: eventContact.guestCount
		}))
	}

	return data
}
