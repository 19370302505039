/* eslint-disable */
const partnerEventsMessages = {
	resources: {
		partner_events: {
			create: "Ajouter une manifestation",
			edit: "Modifier une manifestation",
			name: "Club partenaire",
			title: "Liste des manifestations",
			export_addresses: "Exporter les étiquettes",
			details: {
				titles: {
					info: "Informations",
					event: "Évènement"
				},
				name: "Détails",
				fields: {
					name: "Titre",
					description: "Description",
					start_time: "Début de l'évènement",
					end_time: "Fin de l'évènement",
					place: "Lieu de l'évènement",
					patronage_count: "Mécènes"
				}
			},
			organizations: {
				name: "Organismes",
				fields: {
					name: "Organisme",
					contacts: "Contacts",
					organization: "Organisme",
					organizations: "Organismes",
					available: "Disponibles",
					sent: "Envoyées",
					empty_selected: "Sélectionner au moins un organisme via le sélecteur ci-dessus",
					empty_start_time: "Vous devez d'abord renseigner une date pour l'événement.",
					active_title: "Organismes invités à l'évènement",
					select_organizations_title: "Sélectionner des organismes",
					delete_organization: "Supprimer cet organisme"
				}
			},
			email: {
				name: "E-mail",
				fields: {
					name: "Objet de l'email",
					planned_at: "Date d'envoi",
					template: "Template de l'email",
					email_address_id: "Expéditeur",
					template_header: "Aperçu du template",
					template_select: "Sélectionner un template pour visualiser son aperçu",
					mailjet_vars: "Variables",
					mailjet_edit: "Modifier"
				},
				errors: {
					loading: "Chargement de données mailjet en cours"
				}
			},
			notifications: {
				create: "Manifestation %{name} créée",
				edit: "Manifestation %{name} modifiée"
			},
			statistics: {
				name: "Statistiques",
				general_label: "Général",
				mail: "invitation envoyée||||invitations envoyées",
				tickets_label: "Places",
				sent: "envoyée||||envoyées",
				used: "utilisée||||utilisées",
			},
			errors: {
				start_time_before: "La date de début doit être supérieure à la date d'aujourd'hui",
				end_time_before: "La date de fin doit être supérieure à la date de debut"
			}
		}
	}
}

export default partnerEventsMessages
