/* eslint-disable no-console, max-len, max-lines, max-lines-per-function, @typescript-eslint/no-shadow */
import React, { useEffect, useRef, useState } from "react"
import { isEmpty } from "lodash"
import {
	AutocompleteArrayInput,
	Button,
	Loading,
	ReferenceArrayInput,
	TextInput,
	useRefresh,
	useTranslate
} from "react-admin"
import {
	Box,
	Checkbox,
	Chip,
	Grid,
	IconButton,
	Paper,
	Stack,
	TableContainer,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography
} from "@mui/material"
import { useFormContext } from "react-hook-form"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import CheckIcon from "@mui/icons-material/Check"
import ClearIcon from "@mui/icons-material/Clear"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import UndoIcon from "@mui/icons-material/Undo"

import DataGrid from "utils/DataGrid"
import DataGridSearch from "utils/DataGridSearch"
import { getUrl } from "utils/api"
import FormGrid from "utils/FormGrid"
import sx from "utils/sx"
import Cookies from "js-cookie"
import { SectionTitle } from "utils/SectionTitle"

interface IRecipient {
	id: number
	firstName: string
	lastName: string
	selected: boolean
	groups?: any[]
	organization: any
	response?: boolean
	guestCount?: number
}

interface IRecipientListProps {
	eventId: number
	recipients: IRecipient[]
	handleRemove: (recipient: IRecipient) => void
	handleUpdate: (recipient: IRecipient) => void
	enableResponse: boolean
	searchQueryRef: React.MutableRefObject<string>
	pageRef: React.MutableRefObject<number>
}

const RecipientsList = ({
	eventId,
	recipients,
	handleRemove,
	handleUpdate,
	enableResponse,
	searchQueryRef,
	pageRef
}: IRecipientListProps) => {
	const translate = useTranslate()
	const { getValues } = useFormContext()
	const refresh = useRefresh()

	if (recipients.length === 0) {
		return null
	}

	const defaultEditValues: IRecipient = {
		id: 0,
		firstName: "",
		lastName: "",
		selected: true,
		groups: [],
		organization: null,
		response: undefined,
		guestCount: undefined
	}

	const [editIndex, setEditIndex] = useState<number>(-1)
	const [editValues, setEditValues] = useState(defaultEditValues)

	useEffect(
		() => {
			if (editIndex > -1) {
				setEditValues(getValues(`eventContacts.${editIndex}`))
			} else {
				setEditValues(defaultEditValues)
			}
		},
		[editIndex]
	)

	const getRecipientName = ({ firstName, lastName, organization }: IRecipient) => (
		`${lastName ?? ""} ${firstName ?? ""} ${organization?.name ? ` (${organization.name})` : ""}`
	)

	const setEditValue = (key: string, value: number | boolean) => {
		const newValues = {
			...editValues,
			[key]: value
		}

		if (key === "response") {
			if (value !== true) {
				newValues.guestCount = undefined
			} else if (value === true && !newValues.guestCount) {
				newValues.guestCount = 1
			}
		}

		setEditValues(newValues)
	}

	const handleSave = async () => {
		const response = await fetch(getUrl(`/events/${eventId}/event-contacts`), {
			headers: new Headers({
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`
			}),
			body: JSON.stringify(editValues),
			method: "PUT"
		})

		const data = await response.json()

		if (!data.statusCode) {
			handleUpdate(data)
		}

		refresh()
		setEditIndex(-1)
	}

	const handleUndo = () => {
		setEditIndex(-1)
	}

	const isEditing = (index: number) => index > -1 && editIndex === index

	return (
		<DataGridSearch<IRecipient>
			items={recipients}
			searchQueryRef={searchQueryRef}
			searchIn={getRecipientName}
			label={translate("resources.events.recipients.fields.search")}
		>
			{({ searchItems, getItemIndex }) => (
				<DataGrid<IRecipient>
					columns={[
						{
							name: "name",
							header: "resources.events.recipients.fields.contactName",
							renderItem: getRecipientName,
							sortable: true,
							customSort: ["lastName", "firstName"],
							sx: sx.column50
						},
						{
							name: "response",
							header: "resources.events.recipients.fields.response",
							renderItem: (recipient) => (
								isEditing(getItemIndex(recipient))
									? (
										<ToggleButtonGroup
											value={editValues.response}
											exclusive
											onChange={(event, value) => setEditValue("response", value)}
											aria-label={translate("resources.events.recipients.fields.response")}
										>
											<ToggleButton
												value={true}
												color="primary"
												aria-label={translate("resources.events.recipients.fields.accepted")}
											>
												<CheckIcon />
											</ToggleButton>
											<ToggleButton
												value={false}
												color="error"
												aria-label={translate("resources.events.recipients.fields.refused")}
											>
												<ClearIcon />
											</ToggleButton>
										</ToggleButtonGroup>
									)
									: (
										(recipient.response === true && (
											<CheckIcon
												color="primary"
												titleAccess={translate("resources.events.recipients.fields.accepted")}
											/>
										))
										|| (recipient.response === false && (
											<ClearIcon
												color="error"
												titleAccess={translate("resources.events.recipients.fields.refused")}
											/>
										))
									)
							),
							sx: {
								...sx.column25,
								textAlign: "center"
							}
						},
						{
							name: "guestCount",
							header: "resources.events.recipients.fields.guestCount",
							renderItem: (recipient) => (
								isEditing(getItemIndex(recipient))
									? (
										<TextField
											type="number"
											inputProps={{
												"aria-label": translate("resources.events.recipients.fields.guestCount"),
												min: 1
											}}
											label=""
											variant="standard"
											value={editValues.guestCount}
											onChange={(event) => setEditValue("guestCount", Number(event.target.value))}
											disabled={editValues.response !== true}
										/>
									)
									: recipient.guestCount && <Chip label={recipient.guestCount} size="small" color="primary" />
							),
							sx: {
								...sx.column25,
								textAlign: "center"
							}
						},
						{
							name: "actions",
							header: "",
							renderItem: (recipient) => {
								const index = getItemIndex(recipient)

								return isEditing(index)
									? (
										<Stack direction="row" spacing={2}>
											<IconButton
												edge="end"
												title={translate("resources.events.recipients.fields.save_recipient")}
												onClick={handleSave}
												color="primary"
											>
												<SaveIcon />
											</IconButton>
											<IconButton
												edge="end"
												title={translate("resources.events.recipients.fields.undo_recipient")}
												onClick={handleUndo}
											>
												<UndoIcon />
											</IconButton>
										</Stack>
									)
									: (
										<Stack direction="row" spacing={2}>
											<IconButton
												edge="end"
												title={translate("resources.events.recipients.fields.edit_recipient")}
												onClick={() => setEditIndex(index)}
												disabled={!enableResponse}
											>
												<EditIcon />
											</IconButton>
											<IconButton
												edge="end"
												title={translate("resources.events.recipients.fields.delete_recipient")}
												onClick={() => handleRemove(recipient)}
												disabled={recipient.response !== null}
											>
												<DeleteIcon />
											</IconButton>
										</Stack>
									)
							}
						}
					]}
					items={searchItems}
					defaultSort={{
						column: "name"
					}}
					pageRef={pageRef}
				/>
			)}
		</DataGridSearch>
	)
}

interface ResultsTextProps {
	searchLoading: boolean
	searchQuery: string
	groupQuery: number[]
	orgQuery: number[]
}

const ResultsText = ({
	searchLoading,
	searchQuery,
	groupQuery,
	orgQuery
}: ResultsTextProps) => {
	const translate = useTranslate()

	if (searchLoading) {
		return <Loading loadingSecondary="" />
	}

	const textToDisplay = searchQuery === "" && groupQuery.length === 0 && orgQuery.length === 0
		? "resources.events.recipients.fields.empty_selector"
		: "resources.events.recipients.fields.empty_results"

	return <Typography>{translate(textToDisplay)}</Typography>
}

interface RecipientTabProps {
	enableResponse: boolean
}

const RecipientTab = ({ enableResponse }: RecipientTabProps) => {
	const translate = useTranslate()
	const { setValue, getValues } = useFormContext()

	const [searchQuery, setSearchQuery] = useState("")
	const [groupQuery, setGroupQuery] = useState<number[]>([])
	const [orgQuery, setOrgQuery] = useState<number[]>([])

	const [selectableRecipients, setSelectableRecipients] = useState<Array<IRecipient>>([])
	const [recipients, setRecipients] = useState<Array<IRecipient>>(getValues("eventContacts") || [])
	const [selectableIsChecked, setSelectableIsChecked] = useState(false)
	const [searchLoading, setSearchLoading] = useState(false)

	const searchQueryRef = useRef("")
	const listPageRef = useRef(0)
	const pageRef = useRef(0)

	useEffect(
		() => {
			setValue("eventContacts", recipients)
		},
		[recipients]
	)

	const updateSelectableRecipients = async () => {
		setSearchLoading(true)

		const params = {
			name: searchQuery,
			groups: groupQuery,
			organizations: orgQuery
		}

		const stringParams = `filter=${JSON.stringify(params)}`

		const response = await fetch(getUrl(`/contact-organizations?${stringParams}`), {
			headers: new Headers({
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`
			}),
			method: "GET"
		})

		const data = await response.json()
		const recipients = data.map(({ id, contact, groups, organization }: any) => {
			return {
				id,
				firstName: contact.firstName,
				lastName: contact.lastName,
				groups,
				organization,
				response: null,
				guestCount: null,
				selected: false
			}
		})

		setSelectableIsChecked(false)
		setSelectableRecipients(recipients)

		setSearchLoading(false)
	}

	const updateSelectableRecipientsTimer = useRef<NodeJS.Timeout>()

	useEffect(() => {
		pageRef.current = 0
		setSearchLoading(true)

		clearTimeout(updateSelectableRecipientsTimer.current)

		if (searchQuery !== "" || !isEmpty(groupQuery) || !isEmpty(orgQuery)) {
			updateSelectableRecipientsTimer.current = setTimeout(updateSelectableRecipients, 250)

		} else {
			setSelectableIsChecked(false)
			setSelectableRecipients([])
			setSearchLoading(false)
		}
	}, [searchQuery, groupQuery, orgQuery])

	const toggleRecipient = (selectedRecipient: any) => {
		const updatedSelectableRecipients = [...selectableRecipients]

		const index = updatedSelectableRecipients.findIndex((recipient: any) => selectedRecipient.id === recipient.id)

		updatedSelectableRecipients[index].selected = !selectedRecipient.selected

		setSelectableRecipients(updatedSelectableRecipients)
	}

	const uncheckSelectable = () => {
		setSelectableRecipients(selectableRecipients.map(recipient => ({
			...recipient,
			selected: false
		})))
		setSelectableIsChecked(false)
	}

	const isRecipientAdded = (addedRecipient: IRecipient) => (
		!!recipients.find(recipient => recipient.id === addedRecipient.id)
	)

	const toggleSelectableIsChecked = () => {
		const newSelectableRecipients = []
		const isChecked = selectableIsChecked !== true

		for (const recipient of selectableRecipients) {
			if (!isRecipientAdded(recipient)) {
				recipient.selected = isChecked
			}
			newSelectableRecipients.push(recipient)
		}

		setSelectableRecipients(newSelectableRecipients)
		setSelectableIsChecked(isChecked)
	}

	const addRecipients = () => {
		const filteredRecipients = selectableRecipients
			.filter(
				(recipient: any) => {
					if (recipient.selected === false) {
						return false
					}

					const found = recipients.find(element => element.id === recipient.id)

					return found === undefined
				}
			)

		searchQueryRef.current = ""
		pageRef.current = 0

		setRecipients([...filteredRecipients, ...recipients])
		uncheckSelectable()
	}

	const handleAdd = (recipientToAdd: IRecipient) => {
		const alreadyAdded = recipients.find(recipient => recipient.id === recipientToAdd.id)

		searchQueryRef.current = ""
		pageRef.current = 0

		if (!alreadyAdded) {
			setRecipients([recipientToAdd, ...recipients])
		}
	}

	const handleUpdate = (recipientToUpdate: IRecipient) => {
		setRecipients(recipients.map(recipient => (
			recipient.id === recipientToUpdate.id
				? recipientToUpdate
				: recipient
		)))
	}

	const handleRemove = (recipientToRemove: IRecipient) => {
		const newRecipients = recipients.filter(recipient => recipient.id !== recipientToRemove.id)

		setRecipients(newRecipients)
	}

	const selectedRecipients = selectableRecipients.filter(recipient => recipient.selected)

	return (
		<Grid container spacing={4}>
			<Grid item xs={12} sm={6}>
				<Box sx={sx.prose}>
					<SectionTitle label="resources.events.recipients.fields.select_contacts_title" />
					<ReferenceArrayInput
						source="groupQuery"
						reference="groups"
						allowEmpty={true}
					>
						<AutocompleteArrayInput
							label="resources.events.recipients.fields.groups"
							onChange={(value: number[]) => setGroupQuery(value)}
							optionText="name"
							fullWidth
						/>
					</ReferenceArrayInput>
					<ReferenceArrayInput
						source="orgQuery"
						reference="organizations"
						allowEmpty={true}
					>
						<AutocompleteArrayInput
							label="resources.events.recipients.fields.organizations"
							onChange={(value: number[]) => setOrgQuery(value)}
							optionText="name"
							fullWidth
						/>
					</ReferenceArrayInput>
					<TextInput
						fullWidth
						source="searchQuery"
						label="resources.events.recipients.fields.text"
						onChange={(evt: any) => setSearchQuery(evt.target.value)}
						defaultValue={searchQuery}
						value={searchQuery}
					/>

					{selectableRecipients.length > 0
						? <>
							<DataGrid<IRecipient>
								columns={[
									{
										name: "checkbox",
										header: (
											<Checkbox
												edge="start"
												checked={selectableIsChecked}
												disableRipple
												onClick={toggleSelectableIsChecked}
												title={translate(
													"resources.events.recipients.fields.select_all_contacts"
												)}
											/>
										),
										renderItem: (recipient, index) => (
											<Checkbox
												id={`checkbox-${index}`}
												edge="start"
												checked={recipient.selected}
												disableRipple
												onClick={() => toggleRecipient(recipient)}
												inputProps={{ "aria-labelledby": `checkbox-list-label-${index}` }}
												title={translate("resources.events.recipients.fields.select_contact")}
												disabled={isRecipientAdded(recipient)}
											/>
										),
										sx: { padding: 0 }
									},
									{
										name: "name",
										header: "resources.events.recipients.fields.contactName",
										renderItem: ({ firstName, lastName }, index) => (
											<label htmlFor={`checkbox-${index}`} id={`checkbox-list-label-${index}`}>
												{`${lastName ?? ""} ${firstName ?? ""}`}
											</label>
										),
										sx: sx.column50
									},
									{
										name: "groups",
										header: "resources.events.recipients.fields.groups",
										renderItem: ({ groups }) => (
											groups?.map(group => (
												<Chip key={group.id} label={group.name} size="small" />
											))
										),
										sx: sx.column25
									},
									{
										name: "organization",
										header: "resources.events.recipients.fields.organization",
										renderItem: ({ organization }) => (
											organization?.name
										),
										sx: sx.column25
									},
									{
										name: "add",
										header: "",
										renderItem: (recipient) => (
											<IconButton
												edge="end"
												aria-label="add"
												title={translate("resources.events.recipients.fields.add_recipient")}
												onClick={() => handleAdd(recipient)}
												disabled={isRecipientAdded(recipient)}
											>
												<AddIcon />
											</IconButton>
										)
									}
								]}
								items={selectableRecipients}
								sx={{ marginBottom: "2rem" }}
								pageRef={pageRef}
							/>
							<Box sx={{ marginBottom: "2rem" }}>
								<Button
									fullWidth
									variant="contained"
									label={translate(
										"resources.events.recipients.fields.add_selected_recipients",
										{ count: selectedRecipients.length }
									)}
									onClick={addRecipients}
									size="medium"
									startIcon={<AddIcon />}
									disabled={selectedRecipients.length === 0}
								/>
							</Box>
						</>
						: (
							<ResultsText
								searchLoading={searchLoading}
								searchQuery={searchQuery}
								groupQuery={groupQuery}
								orgQuery={orgQuery}
							/>
						)
					}
				</Box>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Box sx={sx.prose}>
					<TableContainer component={(props) => <Paper {...props} variant="outlined" elevation={0} />}>
						<SectionTitle
							label="resources.events.recipients.fields.active_title"
							sx={sx.paperSectionTitle}
						/>

						{recipients.length > 0 || selectedRecipients.length > 0
							? (
								<>
									{selectedRecipients.length > 0 && (
										<Box sx={{ padding: "1rem" }}>
											<Button
												fullWidth
												variant="contained"
												label={translate(
													"resources.events.recipients.fields.add_selected_recipients",
													{ count: selectedRecipients.length }
												)}
												onClick={addRecipients}
												size="medium"
												startIcon={<AddIcon />}
											/>
										</Box>
									)}
									<RecipientsList
										eventId={getValues("id")}
										recipients={recipients}
										handleUpdate={handleUpdate}
										handleRemove={handleRemove}
										enableResponse={enableResponse}
										searchQueryRef={searchQueryRef}
										pageRef={listPageRef}
									/>
								</>
							)
							: (
								<Typography sx={{ padding: "1rem" }}>
									{translate("resources.events.recipients.fields.empty_selected")}
								</Typography>
							)
						}
					</TableContainer>
				</Box>
			</Grid>
		</Grid>
	)
}

export default RecipientTab
