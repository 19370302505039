/* eslint-disable */
const noticesMessages = {
	resources: {
		notices: {
			name: "Notifications",
			title: "Liste des notifications",
			count_of_unread: "%{count} notifications non lues",
			fields: {
				name: "Nom",
				created_at: "Créée le",
				is_read: "Lu",
			}
		}
	}
}

export default noticesMessages
