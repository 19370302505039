/* eslint-disable no-console, max-len, max-lines, max-lines-per-function, @typescript-eslint/no-shadow */
import React, { useEffect, useState } from "react"
import { find } from "lodash"
import Cookies from "js-cookie"
import {
	AutocompleteArrayInput,
	ReferenceArrayInput,
	useTranslate
} from "react-admin"
import {
	Box,
	Chip,
	IconButton,
	TextField,
	Typography
} from "@mui/material"
import { useFormContext } from "react-hook-form"
import DeleteIcon from "@mui/icons-material/Delete"

import DataGrid from "utils/DataGrid"
import { getUrl } from "utils/api"
import sx from "utils/sx"
import { SectionTitle } from "utils/SectionTitle"
import dayjs from "dayjs"

interface IPartnerEventPatronage {
	id: number
	organizationId: number
	organizationName: string
	sent: number
	available: number
	used: number
}

interface IPartnerEventPatronagesListProps {
	partnerEventPatronages: IPartnerEventPatronage[]
	handleUpdate: (organization: IPartnerEventPatronage) => void
	handleRemove: (organization: IPartnerEventPatronage) => void
}

const PartnerEventPatronagesList = ({
	partnerEventPatronages,
	handleUpdate,
	handleRemove
}: IPartnerEventPatronagesListProps) => {
	const translate = useTranslate()

	return (
		<DataGrid<IPartnerEventPatronage>
			columns={[
				{
					name: "organizationName",
					header: "resources.partner_events.organizations.fields.name",
					sx: sx.column50
				},
				{
					name: "tickets.available",
					header: "resources.partner_events.organizations.fields.available",
					renderItem: ({ available, sent }) => (
						<Chip
							label={available - sent}
							size="small"
							color={available < sent
								? "warning"
								: "primary"
							}
						/>
					),
					sx: sx.column25
				},
				{
					name: "sent",
					header: "resources.partner_events.organizations.fields.sent",
					renderItem: (partnerEventPatronage) => (
						<TextField
							type="number"
							inputProps={{
								"aria-label": translate("resources.partner_events.organizations.fields.sent"),
								min: 0,
								step: 1
							}}
							label=""
							variant="standard"
							value={partnerEventPatronage.sent}
							onChange={(event) => handleUpdate({
								...partnerEventPatronage,
								sent: Number(event.target.value)
							})}
						/>
					),
					sx: sx.column25
				},
				{
					name: "actions",
					header: "",
					renderItem: (partnerEventPatronage) => (
						<IconButton
							edge="end"
							title={translate("resources.partner_events.organizations.fields.delete_organization")}
							onClick={() => handleRemove(partnerEventPatronage)}
						>
							<DeleteIcon />
						</IconButton>
					)
				}
			]}
			items={partnerEventPatronages}
		/>
	)
}

const PatronagesTab = () => {
	const translate = useTranslate()
	const { setValue, getValues, watch } = useFormContext()

	const hasStartTime = watch("startTime")

	const startTime = getValues("startTime")

	const [orgQuery, setOrgQuery] = useState<IPartnerEventPatronage["id"][]>([])
	const [partnerEventPatronages, setPartnerEventPatronages] = useState<IPartnerEventPatronage[]>(getValues("partnerEventPatronages") || [])

	const addPartnerEventPatronages = async () => {
		const organizationsToAdd = orgQuery.filter((id: IPartnerEventPatronage["id"]) => (
			!find(partnerEventPatronages, ["organizationId", id])
		))

		if (organizationsToAdd.length === 0) {
			return
		}

		const params = {
			id: organizationsToAdd,
			patronageSeason: startTime
		}

		const stringParams = `filter=${JSON.stringify(params)}`

		const response = await fetch(getUrl(`/organizations?${stringParams}`), {
			headers: new Headers({
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`
			}),
			method: "GET"
		})

		const data = await response.json()

		const newPartnerEventPatronage = data.map(({ id, name, patronages }: any) => {
			const patronage = patronages.find((patronage: any) => (
				dayjs(startTime).isBetween(patronage.startTime, patronage.endTime, "day", "[]")
			))

			return {
				id: null,
				organizationId: id,
				organizationName: name,
				sent: 0,
				available: patronage.ticket.total - patronage.ticket.sent,
				used: 0
			}
		})

		setPartnerEventPatronages((partnerEventPatronages) => [
			...newPartnerEventPatronage,
			...partnerEventPatronages
		])
	}

	const handleUpdate = (partnerEventPatronageToUpdate: IPartnerEventPatronage) => {
		setPartnerEventPatronages(partnerEventPatronages.map((partnerEventPatronage) => (
			partnerEventPatronage.organizationId === partnerEventPatronageToUpdate.organizationId
				? partnerEventPatronageToUpdate
				: partnerEventPatronage
		)))
	}

	const handleRemove = (partnerEventPatronageToRemove: IPartnerEventPatronage) => {
		setPartnerEventPatronages(partnerEventPatronages.filter((partnerEventPatronage) => (
			partnerEventPatronage.organizationId !== partnerEventPatronageToRemove.organizationId
		)))
	}

	useEffect(
		() => {
			if (orgQuery.length === 0) {
				return
			}

			addPartnerEventPatronages()
		},
		[orgQuery]
	)

	useEffect(
		() => {
			setValue("partnerEventPatronages", partnerEventPatronages)
		},
		[partnerEventPatronages]
	)

	if (!hasStartTime) {
		return (
			<Box sx={sx.prose}>
				<Typography>
					{translate("resources.partner_events.organizations.fields.empty_start_time")}
				</Typography>
			</Box>
		)
	}

	return (
		<Box sx={sx.prose}>
			<SectionTitle label="resources.partner_events.organizations.fields.select_organizations_title" />
			<ReferenceArrayInput
				source="orgQuery"
				filter={{
					patronageSeason: startTime
				}}
				sort={{
					field: "name",
					order: "ASC"
				}}
				reference="organizations"
				allowEmpty={true}
			>
				<AutocompleteArrayInput
					label="resources.partner_events.organizations.fields.organizations"
					onChange={(value: number[]) => setOrgQuery(value)}
				/>
			</ReferenceArrayInput>

			<SectionTitle
				label="resources.partner_events.organizations.fields.active_title"
			/>

			{partnerEventPatronages.length > 0
				? <PartnerEventPatronagesList
					partnerEventPatronages={partnerEventPatronages}
					handleUpdate={handleUpdate}
					handleRemove={handleRemove}
				/>
				: <Typography>
					{translate("resources.partner_events.organizations.fields.empty_selected")}
				</Typography>
			}
		</Box>
	)
}

export default PatronagesTab
