/* eslint-disable max-lines-per-function, function-paren-newline */
import React, { CSSProperties, useEffect, useState } from "react"
import { camelCase } from "lodash"
import {
	TextInput,
	DateTimeInput,
	ReferenceInput,
	SelectInput,
	useRecordContext,
	useTranslate,
	required
} from "react-admin"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

import { ITemplateData } from "./interfaces/i-template-data"
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material"
import sx from "utils/sx"
import { SectionTitle } from "utils/SectionTitle"
import FormGrid from "utils/FormGrid"
import { fetchTemplatePreview, getTemplateEditLink } from "utils/mailjet"
import MailjetVars from "utils/MailjetVars"

interface EmailTabProps {
	templatesData: ITemplateData[] | undefined
	type: "email" | "follow_up"
}

const EmailTab = ({ templatesData, type }: EmailTabProps) => {
	const translate = useTranslate()
	const record = useRecordContext()

	const typeCamel = camelCase(type)

	const [activeMailTemplateId, setActiveMailTemplateId] = useState<ITemplateData["id"]>(
		(!!record && record[typeCamel]?.template) || 0
	)
	const [activeMailTemplateHtml, setActiveMailTemplateHtml] = useState<ITemplateData["html"]>("")

	useEffect(
		() => {
			const updateActiveMailTemplateHtml = async () => {
				setActiveMailTemplateHtml(await fetchTemplatePreview(activeMailTemplateId))
			}

			if (activeMailTemplateId > 0) {
				updateActiveMailTemplateHtml()
			}
		},
		[activeMailTemplateId]
	)

	const iFrameStyle: CSSProperties = {
		display: "block",
		minHeight: "60vh",
		width: "100%",
		border: "none"
	}

	return !!templatesData && templatesData.length > 0
		? (
			<Grid container spacing={4}>
				<Grid item xs={12} sm={6}>
					<Box sx={sx.prose}>
						<TextInput
							label={`resources.events.${type}.fields.name`}
							source={`${typeCamel}.name`}
							fullWidth
							validate={required()}
						/>
						<SelectInput
							label={`resources.events.${type}.fields.template`}
							source={`${typeCamel}.template`}
							choices={templatesData.map((template: any) => ({
								id: template.id,
								name: `${template.id} - ${template.name}`
							}))}
							onChange={(event: any) => setActiveMailTemplateId(parseInt(event.target.value, 10))}
							fullWidth
						/>
						<FormGrid columnWidths={[6]}>
							<DateTimeInput
								label={`resources.events.${type}.fields.planned_at`}
								source={`${typeCamel}.plannedAt`}
								fullWidth
							/>
						</FormGrid>
						<ReferenceInput
							source={`${typeCamel}.emailAddressId`}
							reference="email-addresses"
						>
							<SelectInput
								label={`resources.events.${type}.fields.email_address_id`}
								optionText="email"
								fullWidth
							/>
						</ReferenceInput>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Paper variant="outlined" elevation={0} sx={{ position: "relative" }}>
						<SectionTitle
							label={`resources.events.${type}.fields.template_header`}
							sx={sx.paperSectionTitle}
						/>
						{activeMailTemplateHtml
							? (
								<iframe
									style={iFrameStyle}
									srcDoc={activeMailTemplateHtml}
								/>
							)
							: (
								<Typography sx={{ padding: "1rem" }}>
									{translate(`resources.events.${type}.fields.template_select`)}
								</Typography>
							)
						}
						<Stack
							spacing={1}
							direction="row"
							sx={{
								position: "absolute",
								right: 9,
								top: 9
							}}
						>
							<MailjetVars type="event" />
							{activeMailTemplateId > 0 && (
								<Button
									size="small"
									variant="outlined"
									href={getTemplateEditLink(activeMailTemplateId)}
									endIcon={<OpenInNewIcon/>}
									target="_blank"
								>
									{translate("resources.events.email.fields.mailjet_edit")}
								</Button>
							)}
						</Stack>
					</Paper>
				</Grid>
			</Grid>
		)
		: <Typography>{translate(`resources.events.${type}.errors.loading`)}</Typography>
}

export default EmailTab
