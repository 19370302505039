import React from "react"
import { List } from "@mui/material"

import { UseStatistics } from "utils/statistics"

import ListItem from "./ListItem"

const DirectoryList = ({ statistics }: { statistics: UseStatistics }) => {

	const items = [
		{
			count: statistics.getCountOf("contacts"),
			name: "contacts"
		},
		{
			count: statistics.getCountOf("organizations"),
			name: "organizations"
		},
		{
			count: statistics.getCountOf("groups"),
			name: "groups"
		}
	]

	if (!items) {
		return null
	}

	return (
		<List disablePadding>
			{items.map((item, index) => (
				<ListItem key={index} item={item} />
			))}
		</List>
	)
}

export default DirectoryList
