import React, { MouseEvent } from "react"
import Cookies from "js-cookie"
import {
	BooleanField,
	Button,
	Datagrid,
	DateField,
	List,
	TextField,
	useRecordContext,
	useRefresh
} from "react-admin"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"

import { useNotice } from "contexts/notice.context"
import { getUrl } from "utils/api"

const NoticesList = () => {
	const { reduceCount } = useNotice()
	const refresh = useRefresh()

	const rowStyle = (record: any) => {
		if (record.isRead === false) {
			return { backgroundColor: "#efe" }
		}

		return {}
	}

	const setRead = async (noticeId: any) => {
		await fetch(
			getUrl(`/notices/${noticeId}`),
			{
				headers: new Headers({
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`
				}),
				method: "PUT",
				body: JSON.stringify({ isRead: true })
			}
		)

		reduceCount()
		refresh()
	}

	const SetReadAction = (props: any) => {
		const record = useRecordContext(props)

		if (record.isRead === true) {
			return null
		}

		return <Button
			onClick={(event: MouseEvent) => {
				event.stopPropagation()
				setRead(record.id)
			}}
		>
			<NotificationsNoneIcon />
		</Button>
	}

	return <List
		title="resources.notices.title"
	>
		<Datagrid
			bulkActionButtons={false}
			rowStyle={rowStyle}
		>
			<BooleanField source="isRead"
				label="resources.notices.fields.is_read"
			/>
			<TextField
				source="name"
				label="resources.notices.fields.name"
				sx={{ width: "65%" }}
			/>
			<DateField
				source="createdAt"
				label="resources.notices.fields.created_at"
				sx={{ width: "10%" }}
			/>
			<SetReadAction />
		</Datagrid>
	</List>
}

export default NoticesList
