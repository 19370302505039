import React from "react"
import {
	Avatar,
	Chip,
	ListItem as MuiListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Typography
} from "@mui/material"
import { Link } from "react-router-dom"
import dayjs from "dayjs"

import BusinessIcon from "@mui/icons-material/Business"

const ListItem = ({ partnerEvent }: { partnerEvent: any }) => {

	return (
		<MuiListItem
			button
			component={Link}
			to={`/partner-events/${partnerEvent.id}`}
			sx={{ borderBottom: "1px solid", borderColor: "divider", paddingRight: 12 }}
		>
			<ListItemAvatar sx={{ minWidth: "auto", marginRight: "1rem" }}>
				<Avatar
					variant="rounded"
					sx={(theme) => ({
						backgroundColor: theme.palette.primary.main,
						flexDirection: "column",
						width: "3rem",
						height: "3rem"
					})}
				>
					<Typography sx={{ lineHeight: 1, fontSize: "1.75rem" }}>
						{dayjs(partnerEvent.startTime).format("DD")}
					</Typography>
					<Typography sx={{ lineHeight: 1, fontSize: ".75rem", fontWeight: "bold" }}>
						{dayjs(partnerEvent.startTime).format("MMM")}
					</Typography>
				</Avatar>
			</ListItemAvatar>
			<ListItemText primary={partnerEvent.name} secondary={partnerEvent.place} />
			<ListItemSecondaryAction>
				<Chip
					icon={<BusinessIcon />}
					label={partnerEvent.partnerEventPatronages.length}
					size="small"
				/>
			</ListItemSecondaryAction>
		</MuiListItem>
	)
}

export default ListItem
