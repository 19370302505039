/* eslint-disable */
const organizationsMessages = {
	resources: {
		organizations: {
			create: "Ajouter un organisme",
			edit: "Modifier un organisme",
			name: "Organismes",
			title: "Liste des organismes",
			fields: {
				name: "Nom",
				description: "Description",
				created_at: "Créé le",
				updated_at: "Mis à jour le",
				website_url: "Site internet",
				phone_number: "Numéro de téléphone",
				email: "Email",
				is_archived: "Archivé",
				address: {
					address_1: "Adresse 1",
					address_2: "Adresse 2",
					address_3: "Adresse 3",
					zip_code: "Code postal",
					city: "Ville"
				},
				contacts: "Contacts",
				contact:{
					firstName:"Prénom",
					lastName:"Nom de famille",
					job:"Fonction"
				},
				has_patronage: "Mécène",
				patronage: {
					start_time: "Début",
					end_time: "Fin",
					contacts: {
						contact_organization_id: "Contact",
						is_recipient: "Réception des invitations"
					},
					type: {
						name: "Type",
						options: {
							numerary: "Numéraire",
							material: "Matériel"
						}
					},
					amount: "Montant",
					tickets: {
						total: "Nombre"
					},
					payment_schedules: {
						name: "Échéancier",
						button: "Gérer l'échéancier",
						date: "Date",
						amount: "Valeur",
						is_paid: "Réglé"
					}
				}
			},
			notifications: {
				create: "Organisme %{name} créé",
				edit: "Organisme %{name} modifié"
			},
			titles: {
				default: "Général",
				address:"Adresse",
				contacts:"Contacts",
				patronage: {
					default: "Mécénat",
					season: "Saison",
					season_unknown: "Saison sans titre",
					season_for: "Saison %{season}",
					donation: "Don",
					tickets: "Billets",
					signer: "Signataire",
					contacts: "Contacts associés",
					contacts_loading: "Chargement des contacts...",
					packs: {
						add: "Ajouter un pack de mécénat",
						button: "Utiliser un pack",
						selected_button: "Sélectionné",
						select_button: "Sélectionner",
					},
					agreement: "Convention",
					download_agreement: "Télécharger la convention",
					attachments:"Pièces jointes"
				}
			},
			filters: {
				is_archived: "Afficher les organismes archivés",
				has_patronages: "Filtrer par organismes mécènes"
			},
			errors: {
				patronage: {
					payment_schedules: {
						is_different: "Le montant total de l'échéancier est différent du montant du don (%{paymentSchedulesTotal} ≠ %{donationAmount})"
					},
					contacts: "Votre organisme ne contient aucun contact dont le type d'envoi est \"email\", veuillez en ajouter pour créer une saison de mécénat"
				}
			}
		}
	}
}

export default organizationsMessages
