/* eslint-disable */
const defaultMessages = {
	misc: {
		create: "Ajouter",
		edit: "Éditer",
		read: "Lire",
		delete: "Supprimer",
		deleteContent: "Êtes-vous sûr de vouloir supprimer cette donnée ?"
	},
	platform: {
		trelaze: {
			name: "Trélazé contacts"
		},
		arena: {
			name: "Arena Loire contacts"
		}
	},
	categories: {
		administration: "Administration",
		directory: "Annuaire",
		events: "Manifestations"
	},
	errors: {
		password_confirmation_error: "Les deux mots de passes ne sont pas équivalent",
		required_value: "Ce champ est requis",
		invalid_email_address: "Cette adresse email est invalide"
	}
}

export default defaultMessages
