/* eslint-disable */
const usersMessages = {
	resources: {
		users: {
			profile: "Mon profil",
			create: "Ajouter un utilisateur",
			edit: "Modifier un utilisateur",
			name: "Utilisateurs",
			title: "Liste des utilisateurs",
			fields: {
				created_at: "Créé le",
				email: "Adresse mail",
				first_name: "Prénom",
				last_name: "Nom",
				password: "Mot de passe",
				password_confirmation: "Confirmation de mot de passe",
				updated_at: "Mis à jour le",
			},
			notifications: {
				create: "Utilisateur %{firstName} %{lastName} créé",
				edit: "Fonction %{firstName} %{lastName} modifié"
			}
		}
	}
}

export default usersMessages
