import Cookies from "js-cookie"

import { getUrl } from "utils/api"

export const fetchTemplatesData = async () => {
	const getTemplatesData = await fetch(getUrl("/emails/email-templates"), {
		headers: new Headers({
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`
		}),
		method: "GET"
	})

	return getTemplatesData.json()
}

export const fetchTemplatePreview = async (id: number) => {
	const getTemplatePreview = await fetch(getUrl(`/emails/email-preview/${id}`), {
		headers: new Headers({
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`
		}),
		method: "GET"
	})

	return getTemplatePreview.text()
}

export const getTemplateEditLink = (id: number) => (
	`https://app.mailjet.com/template/${id}/build`
)
