/* eslint-disable */
const contactsMessages = {
	resources: {
		contacts: {
			create: "Ajouter un contact",
			edit: "Modifier un contact",
			name: "Contacts",
			title: "Liste des contacts",
			fields: {
				name: "Nom",
				first_name: "Prénom",
				last_name: "Nom",
				civility: "Civilité",
				civilities: {
					madam: "Madame",
					mister: "Monsieur"
				},
				send_type: "Type d'envoi",
				send_types: {
					email: "Email",
					post: "Postal"
				},
				email: "Email",
				job: "Fonction",
				phone_number: "Numéro de téléphone",
				mobile_number: "Numéro de portable",
				groups: "Groupe(s)",
				organization: "Organisme",
				organizations: "Organisme(s)",
				organization_toggle: "Fait partie d'un/plusieurs organisme(s)",
				is_archived: "Archivé",
				created_at: "Créé le",
				updated_at: "Mis à jour le",
				address: {
					address_1: "Adresse",
					address_2: "Complément d'adresse 1",
					address_3: "Complément d'adresse 2",
					zip_code: "Code postal",
					city: "Ville"
				}
			},
			notifications: {
				create: "Contact %{firstName} %{lastName} créé",
				edit: "Contact %{firstName} %{lastName} modifié"
			},
			titles: {
				address: "Adresse",
				information: "Information",
				contact: "Contact",
				links: "Liens",
				default: "Général",
				organizations: "Organisme(s)",
				isContactForOrganizations:
					"Est contact pour l'organisme/les organismes"
			},
			filters: {
				is_archived: "Afficher les contacts archivés"
			},
			errors: {
				send_type_required_at_least_once:
					"Vous devez renseigner un type d'envoi pour le contact ou un organisme associé",
				send_type_email:
					"L'email est obligatoire pour le type d'envoi email",
				send_type_address:
					"L'adresse est obligatoire pour le type d'envoi postal",
				send_type_zip_code:
					"Le code postal est obligatoire pour le type d'envoi postal",
				send_type_city:
					"La ville est obligatoire pour le type d'envoi postal"
			}
		}
	}
}

export default contactsMessages
