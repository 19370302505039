import React, { useEffect, useState } from "react"
import {
	Create,
	FormTab,
	TabbedForm,
	useNotify,
	useRedirect,
	useRefresh
} from "react-admin"

import PatronagesTab from "./PatronagesTab"
import DetailTab from "./DetailTab"
import EmailTab from "./EmailTab"
import { prepareData } from "./prepareData"
import FormTabWithBadge from "utils/FormTabWithBadge"
import { ITemplateData } from "../events/interfaces/i-template-data"
import { fetchTemplatesData } from "utils/mailjet"
import { validatePartnerEvent } from "./validate-partner-event"

const PartnerEventCreate = () => {
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onSuccess = (data: any) => {
		notify(
			"resources.partner_events.notifications.create",
			{
				type: "info",
				messageArgs: { name: data.name }
			}
		)
		redirect("/partner-events")
		refresh()
	}

	const [templatesData, setTemplatesData] = useState<ITemplateData[]>()

	useEffect(
		() => {
			const init = async () => {
				setTemplatesData(await fetchTemplatesData())
			}

			init()
		},
		[]
	)

	return (
		<Create
			mutationOptions={{ onSuccess }}
			transform={prepareData}
			title="resources.partner_events.create"
		>
			<TabbedForm
				validate={(values: any) => validatePartnerEvent(values)}
			>
				<FormTab label="resources.partner_events.details.name">
					<DetailTab />
				</FormTab>

				<FormTabWithBadge label="resources.partner_events.organizations.name" badge="partnerEventPatronages">
					<PatronagesTab />
				</FormTabWithBadge>

				<FormTab label="resources.partner_events.email.name">
					<EmailTab templatesData={templatesData} type="email" />
				</FormTab>
			</TabbedForm>
		</Create>
	)
}

export default PartnerEventCreate
