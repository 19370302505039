/* eslint-disable max-lines-per-function */
import React from "react"
import {
	AutocompleteArrayInput,
	BooleanField,
	BooleanInput,
	ChipField,
	CreateButton,
	Datagrid,
	DateField,
	DeleteWithConfirmButton,
	EditButton,
	FunctionField,
	List,
	ReferenceArrayInput,
	SearchInput,
	SelectField,
	TopToolbar,
	useLocale
} from "react-admin"

import sx from "utils/sx"

const listFilters = [
	<SearchInput
		source="name"
		alwaysOn
		resettable
	/>,
	<ReferenceArrayInput
		reference="organizations"
		source="organizations"
		sort={{ field: "name", order: "ASC" }}
		alwaysOn
	>
		<AutocompleteArrayInput
			label="resources.contacts.fields.organizations"
			optionText="name"
		/>
	</ReferenceArrayInput>,
	<ReferenceArrayInput
		reference="groups"
		source="groups"
		sort={{ field: "name", order: "ASC" }}
		alwaysOn
	>
		<AutocompleteArrayInput
			label="resources.contacts.fields.groups"
			optionText="name"
		/>
	</ReferenceArrayInput>,
	<BooleanInput
		label="resources.contacts.filters.is_archived"
		source="isArchived"
		alwaysOn
	/>
]

const Actions = () => <TopToolbar>
	<CreateButton label="misc.create" />
</TopToolbar>

const ContactsList = () => {
	const locale = useLocale()

	return <List
		title="resources.contacts.title"
		actions={<Actions />}
		filters={listFilters}
		sort={{ field: "name", order: "ASC" }}
	>
		<Datagrid
			rowClick="edit"
			bulkActionButtons={false}
			sx={sx.datagridColumns}
		>
			<SelectField
				source="civility"
				choices={[
					{ id: 1, name: "resources.contacts.fields.civilities.madam" },
					{ id: 2, name: "resources.contacts.fields.civilities.mister" }
				]}
				label="resources.contacts.fields.civility"
			/>
			<FunctionField
				label="resources.contacts.fields.name"
				source="name"
				render={ (record: any) => `${record.lastName ?? ""} ${record.firstName ?? ""}` }
			/>
			<FunctionField
				headerClassName="column-groups"
				cellClassName="column-groups"
				label="resources.contacts.fields.groups"
				render={
					(record: any) => (
						record.contactOrganizations.map((contactOrganization: any) => (
							contactOrganization.groups.map((group: any, index: number) => (
								<ChipField record={ { name: group.name } } source="name" size="small" key={index} />
							))
						))
					)

				}
			/>
			<FunctionField
				headerClassName="column-organizations"
				cellClassName="column-organizations"
				label="resources.contacts.fields.organizations"
				render={
					(record: any) => (
						record.contactOrganizations.map((contactOrganization: any, index: number) => {
							const { organization } = contactOrganization

							return !!organization && (
								<ChipField record={ { name: organization.name } } source="name" size="small" />
							)
						})
					)

				}
			/>
			<DateField
				source="updatedAt"
				label="resources.contacts.fields.updated_at"
				locales={locale}
			/>
			<BooleanField
				source="isArchived"
				label="resources.contacts.fields.is_archived"
			/>
			<EditButton
				label=""
				size="large"
				sx={sx.datagridButton}
			/>
			<DeleteWithConfirmButton
				label=""
				confirmTitle="misc.delete"
				confirmContent="misc.deleteContent"
				size="large"
				sx={sx.datagridButton}
			/>
		</Datagrid>
	</List>
}

export default ContactsList
