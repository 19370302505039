import React, { useEffect, useRef, useState } from "react"
import { isEmpty } from "lodash"
import { TextField } from "@mui/material"

interface DataSearchItem {
	[x: string]: any
	id: number
}

interface DataSearchHelpers<Item extends DataSearchItem> {
	searchQuery: string
	searchItems: Item[]
	getItemIndex: (item: Item) => number
}

interface DataSearchProps<Item extends DataSearchItem> {
	label: string
	items: Item[]
	searchQueryRef?: React.MutableRefObject<string>
	searchIn: (item: Item) => string
	children: (helpers: DataSearchHelpers<Item>) => React.ReactNode
}

const DataGridSearch = <Item extends DataSearchItem>({
	label,
	items,
	searchQueryRef,
	searchIn,
	children
}: DataSearchProps<Item>) => {

	const [searchQuery, setSearchQuery] = useState(searchQueryRef?.current || "")
	const [searchItems, setSearchItems] = useState(items)

	const searchQueryTimer = useRef<NodeJS.Timeout>()

	useEffect(
		() => {
			if (searchQueryRef) {
				searchQueryRef.current = searchQuery
			}

			const updateSearchItems = () => {
				const searchQueryLower = searchQuery.toLowerCase()

				setSearchItems(
					isEmpty(searchQueryLower)
						? items
						: items.filter((item) => (
							searchIn(item).toLowerCase()
								.includes(searchQueryLower)
						))
				)
			}

			clearTimeout(searchQueryTimer.current)
			searchQueryTimer.current = setTimeout(updateSearchItems, 250)
		},
		[searchQuery]
	)

	const getItemIndex = (item: Item) => items.indexOf(item)

	const helpers = {
		searchQuery,
		searchItems,
		getItemIndex
	}

	return (
		<>
			<TextField
				label={label}
				variant="filled"
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.currentTarget.value)}
				value={searchQuery}
				fullWidth
				sx={{
					margin: 0,
					borderRadius: 0
				}}
			/>
			{children(helpers)}
		</>
	)
}

export default DataGridSearch
