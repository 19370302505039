import React from "react"
import { Button, Title, useTranslate } from "react-admin"
import { Box, Grid } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import ListIcon from "@mui/icons-material/List"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar"
import FolderSharedIcon from "@mui/icons-material/FolderShared"

import useStatistics from "utils/statistics"
import { getPlatform, isPlatform } from "utils/api"

import EventsList from "./events/EventsList"
import PartnerEventsList from "./partner-events/PartnerEventsList"
import DirectoryList from "./directory/DirectoryList"
import DashboardCard from "./DashboardCard"


const Dashboard = () => {
	const translate = useTranslate()
	const statistics = useStatistics({ path: "directory" })

	return (
		<>
			<Title title={translate(`platform.${getPlatform()}.name`)} />
			<Grid container sx={{ marginTop: ".25rem" }} spacing={3}>
				<Grid item xs={12} sm={6}>
					{isPlatform("trelaze") && (
						<DashboardCard
							avatar={<CalendarMonthIcon/>}
							title="dashboard.events.name"
							actions={
								<Box
									sx={{
										display: "flex",
										flexGrow: 1,
										justifyContent: "space-between",
										flexWrap: "wrap"
									}}
								>
									<Button
										href="/#/events"
										label="dashboard.events.list"
										startIcon={<ListIcon/>}
									/>
									<Button
										href="/#/events/create"
										label="dashboard.events.add"
										startIcon={<AddIcon/>}
									/>
								</Box>
							}
						>
							<EventsList/>
						</DashboardCard>
					)}
					{isPlatform("arena") && (
						<DashboardCard
							avatar={<PermContactCalendarIcon/>}
							title="dashboard.partner_events.name"
							actions={
								<Box
									sx={{
										display: "flex",
										flexGrow: 1,
										justifyContent: "space-between",
										flexWrap: "wrap"
									}}
								>
									<Button
										href="/#/partner-events"
										label="dashboard.partner_events.list"
										startIcon={<ListIcon/>}
									/>
									<Button
										href="/#/partner-events/create"
										label="dashboard.partner_events.add"
										startIcon={<AddIcon/>}
									/>
								</Box>
							}
						>
							<PartnerEventsList/>
						</DashboardCard>
					)}
				</Grid>
				<Grid item xs={12} sm={6}>
					<DashboardCard
						avatar={<FolderSharedIcon/>}
						title="dashboard.directory.name"
					>
						<DirectoryList statistics={statistics}/>
					</DashboardCard>
				</Grid>
			</Grid>
		</>
	)
}

export default Dashboard
