/* eslint-disable multiline-ternary */
import React, { useEffect, ReactElement, ReactNode } from "react"
import { useLocation } from "react-router-dom"
import { isArray } from "lodash"
import {
	List,
	MenuItem,
	ListItemIcon,
	Typography,
	Collapse,
	Tooltip
} from "@mui/material"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { useTranslate, useSidebarState } from "react-admin"

interface Props {
	dense: boolean
	handleToggle: () => void
	icon: ReactElement
	isOpen: boolean
	name: string
	children: ReactNode
}

const SubMenu = (props: Props) => {
	const { handleToggle, isOpen, name, icon, children, dense } = props
	const translate = useTranslate()

	const [sidebarIsOpen] = useSidebarState()

	const location = useLocation()

	useEffect(
		() => {
			const hasSubMenuSelected = () => {
				const selectedChildren = React.Children.map(children, (child) => {
					if (React.isValidElement(child) && child.props.to) {
						return location.pathname.includes(child.props.to.pathname)
					}

					return false
				})

				if (isArray(selectedChildren)) {
					return selectedChildren.some((child) => child === true)
				}

				return false
			}

			if (hasSubMenuSelected() && !isOpen) {
				handleToggle()
			}
		},
		[location]
	)

	const header = <MenuItem dense={dense} onClick={handleToggle}>
		<ListItemIcon sx={{ minWidth: 5 }}>
			{isOpen ? <ExpandMore /> : icon}
		</ListItemIcon>
		<Typography variant="inherit" color="textSecondary">
			{translate(name)}
		</Typography>
	</MenuItem>

	return (
		<div>
			{sidebarIsOpen || isOpen
				? header
				: <Tooltip title={translate(name)} placement="right">
					{header}
				</Tooltip>
			}
			<Collapse in={isOpen} timeout="auto" unmountOnExit>
				<List
					dense={dense}
					component="div"
					disablePadding
					sx={{
						"& a": {
							transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
							paddingLeft: sidebarIsOpen ? 4 : 2
						}
					}}
				>
					{children}
				</List>
			</Collapse>
		</div>
	)
}

export default SubMenu
