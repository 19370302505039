/* eslint-disable no-alert */
import * as exceljs from "exceljs"
import { saveAs } from "file-saver"
import Cookies from "js-cookie"

import { getUrl } from "utils/api"

import { ContactOrgaInterface } from "./interfaces"

const saveFileToUseClient = (fileToSave: Blob, groupName: string): void => {
	if (fileToSave.size === 0) {
		alert("Erreur lors de la génération du fichier, réessayer")

		return
	}

	const today = new Date()
	const date = today.getDate().toString()
		.padStart(2, "0")
	const month = `${today.getMonth() + 1}`
		.padStart(2, "0")

	const year = today.getFullYear()
	const filename = `${groupName}-${date}-${month}-${year}.xlsx`

	saveAs(fileToSave, filename)
}

export const generateXLSXOfGroupsContacts = async (group: any): Promise<void> => {
	const workbook = new exceljs.Workbook()

	const ws = workbook.addWorksheet(group.name)

	const getGroupsContact = await fetch(getUrl(`/groups/${group.id}/contacts`), {
		headers: new Headers({
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`
		}),
		method: "GET"
	})

	ws.addRow([
		"Civilité",
		"Nom",
		"Prénom",
		"Adresse",
		"Complément d'adresse 1",
		"Complément d'adresse 2",
		"Code postal",
		"Ville",
		"Fonction",
		"Numéro de téléphone fixe",
		"Numéro de téléphone mobile",
		"E-mail",
		"Organisme"
	])

	const data = await getGroupsContact.json()

	data.map((contactOrga: ContactOrgaInterface) => {
		ws.addRow([
			contactOrga.contact.civility === 2
				? "Monsieur"
				: "Madame",
			contactOrga.contact.lastName,
			contactOrga.contact.firstName,
			contactOrga.address
				? contactOrga.address.address1
				: null,
			contactOrga.address
				? contactOrga.address.address2
				: null,
			contactOrga.address
				? contactOrga.address.address3
				: null,
			contactOrga.address
				? contactOrga.address.zipCode
				: null,
			contactOrga.address
				? contactOrga.address.city
				: null,
			contactOrga.job
				? contactOrga.job.name
				: null,
			contactOrga.phoneNumber,
			contactOrga.mobileNumber,
			contactOrga.email,
			contactOrga.organization
				? contactOrga.organization.name
				: "Individuel"
		])

		return true
	})

	const buffer = await workbook.xlsx.writeBuffer()

	saveFileToUseClient(
		new Blob([buffer], { type: "application/octet-stream" }),
		group.name
	)
}
