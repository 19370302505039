type Platform = "trelaze" | "arena"

type Env = {
	apiUrl: string
	platform: Platform
}

const env = () => {
	const vars = {
		apiUrl: process.env.REACT_APP_API_URL,
		platform: process.env.REACT_APP_PLATFORM
	}

	if (process.env.NODE_ENV === "production") {
		vars.apiUrl = (window as any).REACT_APP_API_URL
		vars.platform = (window as any).REACT_APP_PLATFORM
	}

	return vars as Env
}

export const getUrl = (route: string): string => {
	return `${env().apiUrl}${route}`
}

export const isPlatform = (platform: Platform): boolean => (
	env().platform === platform
)

export const getPlatform = (): Platform => env().platform
