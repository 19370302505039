import React from "react"
import { Box } from "@mui/material"
import {
	Create,
	required,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh
} from "react-admin"

import sx from "utils/sx"

const GroupCreate = () => {
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onSuccess = (data: any) => {
		notify(
			"resources.groups.notifications.create",
			{
				type: "info",
				messageArgs: { name: data.name }
			}
		)
		redirect("/groups")
		refresh()
	}

	return (
		<Create
			mutationOptions={{ onSuccess }}
			title="resources.groups.create"
		>
			<SimpleForm>
				<Box sx={sx.prose}>
					<TextInput
						label="resources.groups.fields.name"
						source="name"
						validate={required()}
						fullWidth
					/>
					<TextInput
						label="resources.groups.fields.description"
						source="description"
						validate={required()}
						multiline
						fullWidth
					/>
				</Box>
			</SimpleForm>
		</Create>
	)
}

export default GroupCreate
