/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MouseEvent } from "react"
import {
	BooleanField,
	BooleanInput,
	Button,
	CreateButton,
	Datagrid,
	DateField,
	EditButton,
	List,
	SearchInput,
	TextField,
	TopToolbar,
	useRecordContext
} from "react-admin"
import { generateXLSXOfGroupsContacts } from "./ExportContactsOfGroup"
import GetAppIcon from "@mui/icons-material/GetApp"

import sx from "utils/sx"

const listFilters = [
	<SearchInput
		source="name"
		alwaysOn
		resettable
	/>,
	<BooleanInput
		label="resources.groups.filters.is_archived"
		source="isArchived"
		alwaysOn
	/>
]

const Actions = () => <TopToolbar>
	<CreateButton label="misc.create" />
</TopToolbar>


const ExportAction = (props: any) => {
	const record = useRecordContext(props)

	return <Button
		onClick={(event: MouseEvent) => {
			event.stopPropagation()
			generateXLSXOfGroupsContacts(record)
		}}
		size="large"
		sx={sx.datagridButton}
	>
		<GetAppIcon htmlColor="green" />
	</Button>
}

const GroupsList = () => {
	return <List
		actions={<Actions />}
		title="resources.groups.title"
		filters={listFilters}
		sort={{ field: "name", order: "ASC" }}
	>
		<Datagrid
			rowClick="edit"
			bulkActionButtons={false}
			sx={sx.datagridColumns}
		>
			<TextField
				headerClassName="column-fullName"
				cellClassName="column-fullName"
				source="name"
				label="resources.groups.fields.name"
			/>
			<DateField
				source="createdAt"
				label="resources.groups.fields.created_at"
			/>
			<DateField
				source="updatedAt"
				label="resources.groups.fields.updated_at"
			/>
			<BooleanField
				source="isArchived"
				label="resources.groups.fields.is_archived"
			/>
			<EditButton
				label=""
				size="large"
				sx={sx.datagridButton}
			/>
			<ExportAction />
		</Datagrid>
	</List>
}

export default GroupsList
